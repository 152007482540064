import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  useToast,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Heading,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Components/Dealer/Navbar";
import Cookies from "js-cookie";

import {
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaBell,
  FaSearch,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  // fetchBasketClientList,
  fetchDealerClientLists,
  // fetchClients,
  sendMessageToClient,
  fetchAllClientLists,
  fetchDealerClients,
} from "../../Redux/clientReducer/action";
import {
  IoArrowBackCircle,
  IoArrowBackCircleOutline,
  IoChevronBackCircleOutline,
} from "react-icons/io5";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { fetchSymbols } from "../../Redux/symbolReducer/action";
import { fetchLatestBasketList, fetchSingleBasketData } from "../../Redux/basketReducer/action";

export default function DealerBasketDetails() {
  const [data, setData] = useState(null);
  const [basketClientList, setBasketClientList] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientCurrentPage, setClientCurrentPage] = useState(1);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [currentPrimaryPage,setCurrentPrimaryPage] = useState(1)
  const [latestInstrumentPage,setLatestInstrumentPage] = useState(1)
  const [totalFundRequired, setTotalFundRequired] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [toggle, setToggle] = useState(false);
  const [status, setStatus] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [latestInstruments, setLatestInstruments] = useState([]);
  const [primaryInstruments, setPrimaryInstruments] = useState([]);
  const [historyInstruments, setHistoryInstruments] = useState([]);
  const [pendingInstruments,setPendingInstruments] = useState([]);
  const [sentMessages, setSentMessages] = useState({});
  const [rowLoading, setRowLoading] = useState({}); // State to manage row-specific loading

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  let { id } = useParams();
  let token = Cookies.get("login_token_dealer");
  const Symbols = useSelector((store) => store.symbolsReducer.symbols);

  let clientData = useSelector((store) => store.clientsReducer.clients);

  if (clientData == undefined) {
    clientData = [];
  }
  

  useEffect(() => {
    dispatch(fetchAllClientLists(token))
      .then((res) => {
        if (res.data.status === "success") {
          setAllClients(res.data.data.clients);
        }
      })
      .catch((error) => {
        console.log(error, "fetchAllClientLists Error");
      });
  }, []);

  useEffect(() => {
    dispatch(fetchSingleBasketData(id, token))
      .then((res) => {
        // Token expiration check
    
        if (res == undefined) {
          Cookies.set("login_token_dealer", "");
          Cookies.set("username_dealer", "");
          navigate("/dealer");
        }
        if (res.detail === "Token has expired") {
          Cookies.set("login_token_dealer", "");
          Cookies.set("username_dealer", "");
          navigate("/dealer");
        }

        // Permission error handling
        if (res.message === "You do not have permission to access the data") {
          Cookies.set("login_token_dealer", "");
          Cookies.set("username_dealer", "");
          // navigate('/rahead');
        }

        // Basket data
        const basket = res.data.basketList[0]; // Assuming the basket list is returned in the first index
        setData(basket);
        const concerns = basket.concerns;

        let isApproved = false;
        let isRejected = false;
        let isPending = false;
        let isApprovalPending = false; // Updated name

         // Variables to hold the categorized instruments
         let latestInstruments = [];
         let primaryInstruments = [];
         let historyInstruments = [];
         let pendingInstruments = [];
   
        // Check instruments of type "Primary" and raHeadStatus
        concerns.forEach((concern) => {

          const reason = concern.reason; // Extract reason from the current concern
          const status = concern.status
          if(reason){

            // Add `reason` to each instrument
            const instrumentsWithReason = concern.instruments.map((instrument) => ({
              ...instrument,
              reason, // Add the `reason` field to each instrument
              status // Add the status field to the each instrument
            }));
    
            // Add to historyInstruments
            historyInstruments = historyInstruments.concat(instrumentsWithReason);
    
            // Check if instrument is PRIMARY
            if (concern.instrumentType === "PRIMARY") {
              primaryInstruments = primaryInstruments.concat(instrumentsWithReason);
            }
    
            // Check for latest APPROVED concerns
            if (concern.status === "APPROVED") {
              if (
                !latestInstruments.length ||
                new Date(concern.time) >
                  new Date(latestInstruments[0]?.time || 0)
              ) {
                latestInstruments = instrumentsWithReason;
              }
            }
          }
  
          // Check for PENDING concerns
          if (concern.status === "") {
            pendingInstruments = pendingInstruments.concat(concern.instruments);
          }
  

          if (concern.instrumentType === "PRIMARY") {
            if (concern.status === "APPROVED") {
              isApproved = true;
            } else if (concern.status === "") {
              isPending = true;
              // isApprovalRequired = true; // Set true if any raHeadStatus is empty
            } else if (concern.status === "REJECTED") {
              isRejected = true;
            }
          }
        });

        // Check if any instrument has empty raHeadStatus
        isApprovalPending = concerns.some(
          (concern) => concern.status === ""
        );

        // Set the approval pending state
        // setIsApprovalPending(isApprovalPending);

        // Setting the basket status based on the instruments
        if (isRejected) {
          setStatus("REJECTED");
          // setRejected(true);
          // setStatusUpdated(false);
        } else if (isPending) {
          setStatus("PENDING");
          // setRejected(false);
        } else if (isApproved) {
          setStatus("APPROVED");
          // setRejected(false);
          // setStatusUpdated(false);
        }

         // Save categorized data
        //  setLatestInstruments(latestInstruments); // Update with latest APPROVED instruments
         setPrimaryInstruments(primaryInstruments); // Update with all PRIMARY instruments
         setHistoryInstruments(historyInstruments); // Update with all instruments
         setPendingInstruments(pendingInstruments); // Update with PENDING instruments
   
      })
      .catch((error) => console.log(error));
  }, [id, token]);
  useEffect(() => {
    dispatch(fetchDealerClients(token));
    const fetchLatestInstruments = ()=>{
      dispatch(fetchLatestBasketList(id,token))
      .then((res)=>{
       
        if(res.status==='success'){
  
          setLatestInstruments(res.data.basketList[0].concerns)
        }
      })
      .catch((error)=>{
        console.log(error,"error fetchLatestInstruments")
      })
    }
    fetchLatestInstruments()
  }, [dispatch, token, toggle]);

  useEffect(() => {
    if (latestInstruments.length>0) {
      const total = latestInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
      setTotalFundRequired(Math.ceil(total));
    }else if (primaryInstruments.length>0){
      const total = primaryInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
    
      setTotalFundRequired(Math.ceil(total));
    }else {
      const total = pendingInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
    
      setTotalFundRequired(Math.ceil(total));
    }
  }, [data,latestInstruments]);

 

  useEffect(() => {
    dispatch(fetchSymbols(token));
  }, []);

    // Utility function to get today's date as a string (e.g., "2024-12-18")
    const getTodayDate = () => {
      const today = new Date();
      return today.toISOString().split("T")[0];
    };
  
    // Load sent messages from cookies on component mount
    useEffect(() => {
      const today = getTodayDate();
      const savedMessages = Cookies.get(`sent_messages_${today}`);
      if (savedMessages) {
        setSentMessages(JSON.parse(savedMessages));
      }
    }, []);
  
    // Save sent messages to cookies whenever they are updated
    useEffect(() => {
      const today = getTodayDate();
      Cookies.set(`sent_messages_${today}`, JSON.stringify(sentMessages), {
        expires: 1, // Expires at midnight
      });
    }, [sentMessages]);


 
  const handleSendMessage = (client) => {
    const clientID = client.ID;

    // Set loading state for the specific row
    setRowLoading((prev) => ({
      ...prev,
      [clientID]: true,
    }));
const sendData ={
  basketID:id,
  clientID:client.ID
}


// const today = getTodayDate();
// const clientId = client.ID;
// const basketId=id;
// // Update state to mark the message as sent
// setSentMessages((prev) => ({
//   ...prev,
//   [`${basketId}_${clientId}`]: true,
// }));

// // Show a toast notification
// toast({
//   title: "Message Sent",
//   description: `Message sent to ${client.username}.`,
//   status: "success",
//   duration: 2000,
//   isClosable: true,
// });



dispatch(sendMessageToClient(sendData, token))
     .then((res) => {
     
          if (res.status === "success") {
           
            setToggle(!toggle);
            let currentTime= Date.now()
          
            // toast({
            //   title: `Client added to basket.`,
            //   position: "bottom",
            //   status: "success",
            //   duration: 2000,
            //   isClosable: true,
            // });

            

            const today = getTodayDate();
const clientId = client.ID;
const basketId=id;
// Update state to mark the message as sent
setSentMessages((prev) => ({
  ...prev,
  [`${basketId}_${clientId}`]: true,
}));

// Show a toast notification
toast({
  title: "Message Sent",
  description: `Message sent to ${client.username}.`,
  status: "success",
  duration: 2000,
  isClosable: true,
});
          }

          if (res.status === "failed") {
            setToggle(!toggle);
           
            toast({
              title: `${res.message}`,
              position: "bottom",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          }

          if (res.status === "error") {
            setToggle(!toggle);
            toast({
              title: `${res.message} error`,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error, "sendMessageToClient error");
        
          if (error.response.data.detail === "WhatsApp Token has expired") {
            toast({
              title: `${error.response.data.detail} error`,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          } else {
            toast({
              title: `${error.message} error`,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        })

        .finally(() => {
          // Reset the loading state for the specific row
          setRowLoading((prev) => ({
            ...prev,
            [clientID]: false,
          }));
        });




      // dispatch(sendMessageToClient(addBasket, token))
      //   .then((res) => {
       
      //     if (res.status === "success") {
      //       setToggle(!toggle);
      //       let currentTime= Date.now()
          
      //       toast({
      //         title: `Client added to basket.`,
      //         position: "bottom",
      //         status: "success",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     }

      //     if (res.status === "failed") {
      //       setToggle(!toggle);
      //       toast({
      //         title: `${res.message}`,
      //         position: "bottom",
      //         status: "warning",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     }

      //     if (res.status === "error") {
      //       setToggle(!toggle);
      //       toast({
      //         title: `${res.message} error`,
      //         position: "bottom",
      //         status: "error",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error, "sendMessageToClient error");
      //     if (error.response.data.detail === "WhatsApp Token has expired") {
      //       toast({
      //         title: `${error.response.data.detail} error`,
      //         position: "bottom",
      //         status: "error",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     } else {
      //       toast({
      //         title: `${error.message} error`,
      //         position: "bottom",
      //         status: "error",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     }
      //   });
    // } else {
    //   // If no matching client found, show a warning toast
    //   toast({
    //     title: `Client not found.`,
    //     position: "bottom",
    //     status: "warning",
    //     duration: 2000,
    //     isClosable: true,
    //   });
    // }
  };

  if (!data) {
    return (
      <Box>
        <Navbar />
        <Text>Loading....</Text>
      </Box>
    );
  }

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const instruments = Object.values(data.concerns);
  const totalPages = Math.ceil(instruments.length / itemsPerPage);
  // const totalClientPages = Math.ceil(clientData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChangeClient = (newPage) => {
    setClientCurrentPage(newPage);
  };

  const instrumentStartIndex = (currentPage - 1) * itemsPerPage;
  const instrumentEndIndex = instrumentStartIndex + itemsPerPage;

  const currentInstruments = instruments.slice(
    instrumentStartIndex,
    instrumentEndIndex
  );

  const isClientInBasket = (clientId) => {
    if (!basketClientList || basketClientList.length === 0) return false;
    return basketClientList.some((client) => client.clientId === clientId);
  };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  //   setClientCurrentPage(1); // Reset to first page on new search
  // };

  // Handle search change and reset page
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setClientCurrentPage(1); // Reset to first page on new search
  };

  // Calculate filteredClients and update pagination

  const filteredClients = clientData!==null&&clientData.filter(
    (client) =>
     
      client?.centrumID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client?.username?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate total pages based on filtered results
  const totalClientPages = Math.ceil(filteredClients.length / itemsPerPage);

  // Calculate pagination indices
  const clientStartIndex = (clientCurrentPage - 1) * itemsPerPage;
  const clientEndIndex = clientStartIndex + itemsPerPage;
  const currentInstrumentsClient = filteredClients.slice(
    clientStartIndex,
    clientEndIndex
  );


  const calculateQuantile = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const stopLoss = instrumentListData.stopLoss;
    const takeProfit = instrumentListData.takeProfit;
    const cmp = instrumentListData.cmp;
    const quantile = (cmp - stopLoss) / (takeProfit - stopLoss);
    // Convert quantile to percentage
    const quantilePercentage = quantile * 100;

    let Result = Math.floor(quantilePercentage);

    return `${Result}%`;
  };

  const calculateFundREquired = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const cmp = instrumentListData.cmp;
    const fundRequired = (cmp * qty);

    return fundRequired;
  };

  const InstrumentRow = ({ instrument }) => {
    const quantileValue = parseFloat(calculateQuantile(instrument));

    let badgeText;
    let badgeColor;

    if (quantileValue > 100) {
      badgeText = "Book Profit";
      badgeColor = "green";
    } else if (quantileValue > 0 && quantileValue <= 100) {
      badgeText = "Intrade";
      badgeColor = "green";
    } else {
      badgeText = "Stop Loss";
      badgeColor = "red";
    }

    return <Badge colorScheme={badgeColor}>{badgeText}</Badge>;
  };

  const handleOrderStatus = (ClientId) => {
    // Ensure basketClientList is defined and has elements
    if (!basketClientList || basketClientList.length === 0) return "N/A";

    const orderStatus = basketClientList.filter(
      (ele) => ele.clientId === ClientId
    );

    // If orderStatus is found, return the first match
    if (orderStatus.length > 0) {
      return orderStatus[0].orderStatus;
    }

    // Default return value if no match is found
    return "N/A";
  };

  const handleAddAllClients = () => {
    const promises = currentInstrumentsClient.map((client) => {
      const addBasket = {
        basketId: id,
        clientId: client._id,
        // dealerId: client.dealerId,
      };
      return dispatch(sendMessageToClient(addBasket, token));
    });

    // Wait for all add operations to complete
    Promise.all(promises)
      .then((results) => {
        const successCount = results.filter(
          (res) => res.status === "success"
        ).length;
        const errorCount = results.length - successCount;

        setToggle(!toggle);

        if (successCount > 0) {
          toast({
            title: `${successCount} client(s) added to basket.`,
            position: "bottom",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        if (errorCount > 0) {
          toast({
            title: `${errorCount} client(s) could not be added.`,
            position: "bottom",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          title: `${error.message} error`,
          position: "bottom",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const handleSymbolName = (symbol) => {
    if (symbol !== "" && symbol !== null) {
      let filterSymbolName = Symbols.filter((ele) => ele.instrument == symbol);
      let result = filterSymbolName[0];

      if (result !== undefined) {
        return result.name;
      }
    }

    return symbol; // Return an empty string if the symbol is not found or invalid
  };

  const handleUpsidePotential = (instrumentListData) => {
    let cmp = Number(instrumentListData.cmp);
    let takeProfit = Number(instrumentListData.takeProfit);

    let upsidePotential = ((takeProfit - cmp) / cmp) * 100;

    let upsidePotentialPercentage = Math.floor(upsidePotential);
    if (upsidePotentialPercentage < 0) {
      return `Target Achieved`;
    }
    return `${upsidePotentialPercentage}%`;
  };

  const rationalLines =
    data && data.rationale
      ? data.rationale
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line.length > 0)
      : []; // Default to an empty array if data.rationale is undefined

  const handleBackClick = () => {
    navigate("/dealer/dashboard");
  };

  // Filters for tables

  const newInstruments = instruments.filter(
    (instrument) =>
      instrument.instrumentType === "Secondary" &&
      instrument.raHeadStatus === ""
  );

  // Step 1: Create a count of instruments
  const instrumentCount = instruments.reduce((acc, instrument) => {
    acc[instrument.instrument] = (acc[instrument.instrument] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Filter out instruments based on your criteria
  const secondaryInstruments = instruments
    .filter((instrument, index, self) => {
      // Ensure uniqueness by checking if it's the first occurrence
      const isFirstOccurrence =
        index === self.findIndex((i) => i.instrument === instrument.instrument);

      // Get all instruments with the same ID
      const sameInstruments = self.filter(
        (i) => i.instrument === instrument.instrument
      );

      // Check for the presence of APPROVED and REJECTED statuses
      const hasApproved = sameInstruments.some(
        (i) => i.raHeadStatus === "APPROVED"
      );
      const hasRejected = sameInstruments.some(
        (i) => i.raHeadStatus === "REJECTED"
      );

      // Include the instrument if:
      // 1. It is APPROVED and there are no other APPROVED instruments
      // 2. It is the first occurrence of APPROVED when both statuses exist
      return (
        (instrument.raHeadStatus === "APPROVED" &&
          hasApproved &&
          hasRejected &&
          isFirstOccurrence) ||
        (instrument.raHeadStatus === "APPROVED" &&
          !hasRejected &&
          instrumentCount[instrument.instrument] === 1) ||
        (instrument.raHeadStatus === "REJECTED" &&
          !hasApproved &&
          isFirstOccurrence)
      );
    })
    .reverse();

  let newInstrumentsData = secondaryInstruments.filter(
    (instrument) => instrument.raHeadStatus === "APPROVED"
  );

  // const primaryInstruments = instruments.filter(
  //   (instrument) => instrument.instrumentType === "Primary"
  // );

  let basketHistory = true;
  if (historyInstruments.length == primaryInstruments.length) {
    basketHistory = false;
  }

  // const currentNewInstruments = latestInstruments.slice(startIndex, endIndex);
  // const currentSecondaryInstruments = secondaryInstruments.slice(
  //   startIndex,
  //   endIndex
  // );
  // const currentPrimaryInstruments = primaryInstruments.slice(
  //   startIndex,
  //   endIndex
  // );
 

  // <==========================================History PAgination==============================>
    // const itemsPerPage = 10;
    // Calculate total pages
  const totalHistoryPages = Math.ceil(historyInstruments.length / itemsPerPage);

  // Calculate start and end index for current page
  const startHistoryIndex = (currentHistoryPage - 1) * itemsPerPage;
  const endHistoryIndex = startHistoryIndex + itemsPerPage;

  // Slice data for current page
  const currentHistoryData = historyInstruments.slice(startHistoryIndex, endHistoryIndex);

  // Handle page change
  const handleHistoryPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalHistoryPages) {
      setCurrentHistoryPage(newPage);
    }
  };

  // <=================================== Latest Instruments PAgination =================>
const totalLatestInstrumentPages = Math.ceil(latestInstruments.length/ itemsPerPage);
  // Calculate start and end index for current page
  const startLatestIndex = (latestInstrumentPage - 1) * itemsPerPage;
  const endLatestIndex = startLatestIndex + itemsPerPage;

  // Slice data from current Page

  const currentLatestData = latestInstruments.slice(startLatestIndex,endLatestIndex);

  //Handle Page chanfe 
  const handleLatestInstrumentPageChange = (newPage) => {
    if(newPage>0 && newPage <= totalLatestInstrumentPages){
      setLatestInstrumentPage(newPage)
    }
  }

  // <======================== Primary Instruments PAgination ============================>
    const totalPrimaryInstrumentPages = Math.ceil(primaryInstruments.length/ itemsPerPage);
  
  // Calculate start and end index for current page
  const startPrimaryIndex = (currentPrimaryPage - 1) * itemsPerPage;
  const endPrimaryIndex = startPrimaryIndex + itemsPerPage;

   // Slice data from current Page

   const currentPrimaryData = primaryInstruments.slice(startPrimaryIndex,endPrimaryIndex);


   //Handle Page chanfe 
  const handlePrimaryInstrumentPageChange = (newPage) => {
    if(newPage>0 && newPage <= totalPrimaryInstrumentPages){
      setCurrentPrimaryPage(newPage)
    }
  }
  const formateDate=(date)=>{
    let options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric"
    };
  
    let dateObj = new Date(date);
    
    return  dateObj.toLocaleDateString("en-US",options).replace(',','')
  }
  return (
    <Box>
      <Navbar />

      <Flex align="center" justify="flex-start" mt={2} ml={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={8} />}
          aria-label="Go back"
          onClick={handleBackClick}
          size="lg"
          isRound
          bgColor="#244c9c"
          color="white"
          _hover={{
            transform: "scale(1.2)",
            boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
            bgColor: "#1a3a6b", // Darker shade for hover
          }}
          transition="all 0.3s ease"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.2)"
          _active={{
            transform: "scale(0.95)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Flex>

      <Flex
        direction={{ base: "column", md: "column", lg: "row" }}
        mt="10"
        m={{ base: "2", md: "5" }}
        gap={"10px"}
      >
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mb={{ base: "5", md: "0" }}
          overflowX="auto"
        >
          <Tabs onChange={(index) => setActiveTab(index)} variant="enclosed">
            <TabList>
              <Tab fontWeight="bold">Compositions</Tab>
              <Tab fontWeight="bold">Client</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <>
                  {/* Table 1: pending Instruments  List */}
                  {pendingInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Edit Instrument List
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pendingInstruments.map((instrument, index) => (
                            <Tr
                              key={index}
                              // bg={instrument.orderType === "Entry" ? "green.200" : "red.200"}
                            >
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.creationPrice}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: latestInstruments  List */}
                  {latestInstruments.length > 0 &&basketHistory ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        New Basket
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                              <Th>Creation Date</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentLatestData.map((instrument, index) => (
                            <Tr key={index}>
                              <Td>{startLatestIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.cmp}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                              <Td whiteSpace="nowrap">{formateDate(instrument.createdAt)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>

                       {/* Pagination Controls */}
          {totalLatestInstrumentPages > 1 && (
            <Flex justifyContent="space-between" alignItems="center" mt="6">
              <Button
                onClick={() => handleLatestInstrumentPageChange(latestInstrumentPage - 1)}
                size="sm"
                colorScheme="blue"
                leftIcon={<FaArrowLeft />}
                isDisabled={latestInstrumentPage === 1}
                bg={latestInstrumentPage === 1 ? "gray.300" : "blue.500"}
                _hover={{
                  bg: latestInstrumentPage === 1 ? "gray.300" : "blue.400",
                }}
              >
                Previous
              </Button>

              <Text fontWeight="bold" color="#244c9c">
                Page {latestInstrumentPage} of {totalLatestInstrumentPages}
              </Text>

              <Button
                onClick={() => handleLatestInstrumentPageChange(latestInstrumentPage + 1)}
                size="sm"
                colorScheme="blue"
                rightIcon={<FaArrowRight />}
                isDisabled={latestInstrumentPage === totalLatestInstrumentPages}
                bg={latestInstrumentPage === totalLatestInstrumentPages ? "gray.300" : "blue.500"}
                _hover={{
                  bg: latestInstrumentPage === totalLatestInstrumentPages
                    ? "gray.300"
                    : "blue.400",
                }}
              >
                Next
              </Button>
            </Flex>
          )}
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: History Instruments List */}
                  {basketHistory && historyInstruments.length > 0 ? (
        <Box>
          <Text fontSize="2xl" mt="10" mb="6" fontWeight="bold" color="#244c9c">
            Basket History
          </Text>
          <Table size="md">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Script Name</Th>
                <Th>Quantity</Th>
                <Th>Stop Loss</Th>
                <Th>CMP</Th>
                <Th>Take Profit</Th>
                <Th>Order Type</Th>
                <Th>RaHead Approval</Th>
                {/* <Th>Reason</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {currentHistoryData.reverse().map((instrument, index) => (
                <Tr key={index}>
                  <Td>{startHistoryIndex + index + 1}</Td>
                  <Td>{handleSymbolName(instrument.instrument)}</Td>
                  <Td>{instrument.quantity}</Td>
                  <Td>{instrument.stopLoss}</Td>
                  <Td>{instrument.cmp}</Td>
                  <Td>{instrument.takeProfit}</Td>
                  <Td
                    fontWeight="bold"
                    color={
                      instrument.orderType === "ENTRY"
                        ? "green.200"
                        : "red.200"
                    }
                  >
                    {instrument.orderType}
                  </Td>
                  <Td
                    fontWeight="bold"
                    color={
                      instrument.status === "APPROVED"
                        ? "green.200"
                        : "red.200"
                    }
                  >
                    {instrument.status}
                  </Td>
                  {/* <Td
                          wordBreak="break-word"
                          whiteSpace="nowrap"
                          overflow="auto"
                          maxWidth="60%"
                          padding="4"
                          backgroundColor="gray.50"
                        >
                          {instrument.reason === "" ? (
                            <Text color="gray.500" fontStyle="italic">
                              Pending
                            </Text>
                          ) : (
                            <Text fontSize="sm" color="blackAlpha.800">
                              {instrument.reason}
                            </Text>
                          )}
                        </Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Pagination Controls */}
          {totalHistoryPages > 1 && (
            <Flex justifyContent="space-between" alignItems="center" mt="6">
              <Button
                onClick={() => handleHistoryPageChange(currentHistoryPage - 1)}
                size="sm"
                colorScheme="blue"
                leftIcon={<FaArrowLeft />}
                isDisabled={currentHistoryPage === 1}
                bg={currentHistoryPage === 1 ? "gray.300" : "blue.500"}
                _hover={{
                  bg: currentHistoryPage === 1 ? "gray.300" : "blue.400",
                }}
              >
                Previous
              </Button>

              <Text fontWeight="bold" color="#244c9c">
                Page {currentHistoryPage} of {totalHistoryPages}
              </Text>

              <Button
                onClick={() => handleHistoryPageChange(currentHistoryPage + 1)}
                size="sm"
                colorScheme="blue"
                rightIcon={<FaArrowRight />}
                isDisabled={currentHistoryPage === totalHistoryPages}
                bg={currentHistoryPage === totalHistoryPages ? "gray.300" : "blue.500"}
                _hover={{
                  bg: currentHistoryPage === totalHistoryPages
                    ? "gray.300"
                    : "blue.400",
                }}
              >
                Next
              </Button>
            </Flex>
          )}
        </Box>
      ) : (
       ""
      )}
                  {/* Table 3: Primary Instrument List */}
{currentPrimaryData.length>0? (
   <Box mt={10}>
   {
   primaryInstruments.length > 0 &&basketHistory || pendingInstruments.length>0&& primaryInstruments.length > 0 ? (
     <Text
       fontSize="2xl"
       mt="10"
       mb="6"
       fontWeight="bold"
       color="#244c9c"
     >
       Primary Instrument List
     </Text>
   ) : (
     ""
   )}

   <Table size="md">
     <Thead>
       <Tr>
         <Th>#</Th>
         <Th>Script Name</Th>
         <Th>Quantity</Th>
         <Th>Stop Loss</Th>
         <Th>CMP</Th>
         <Th>Take Profit</Th>
         <Th>Order Type</Th>
         {/* <Th>Reason</Th> */}
       </Tr>
     </Thead>
     <Tbody>
       {currentPrimaryData.map((instrument, index) => (
         <Tr key={index}>
           <Td>{startPrimaryIndex + index + 1}</Td>
           <Td>{handleSymbolName(instrument.instrument)}</Td>
           <Td>{instrument.quantity}</Td>
           <Td>{instrument.stopLoss}</Td>
           <Td>{instrument.cmp}</Td>
           <Td>{instrument.takeProfit}</Td>
           <Td
             fontWeight="bold"
             color={
               instrument.orderType === "ENTRY"
                 ? "green.200"
                 : "red.200"
             }
           >
             {instrument.orderType}
           </Td>
            {/* <Td
                    wordBreak="break-word"
                    whiteSpace="nowrap"
                    overflow="auto"
                    maxWidth="60%"
                    padding="4"
                    backgroundColor="gray.50"
                  >
                    {instrument.reason === "" ? (
                      <Text color="gray.500" fontStyle="italic">
                        Pending
                      </Text>
                    ) : (
                      <Text fontSize="sm" color="blackAlpha.800">
                        {instrument.reason}
                      </Text>
                    )}
                  </Td> */}
         </Tr>
       ))}
     </Tbody>
   </Table>


    {/* Pagination Controls */}
{totalPrimaryInstrumentPages > 1 && (
<Flex justifyContent="space-between" alignItems="center" mt="6">
<Button
onClick={() => handlePrimaryInstrumentPageChange(currentPrimaryPage - 1)}
size="sm"
colorScheme="blue"
leftIcon={<FaArrowLeft />}
isDisabled={currentPrimaryPage === 1}
bg={currentPrimaryPage === 1 ? "gray.300" : "blue.500"}
_hover={{
 bg: currentPrimaryPage === 1 ? "gray.300" : "blue.400",
}}
>
Previous
</Button>

<Text fontWeight="bold" color="#244c9c">
Page {currentPrimaryPage} of {totalPrimaryInstrumentPages}
</Text>

<Button
onClick={() => handlePrimaryInstrumentPageChange(currentPrimaryPage + 1)}
size="sm"
colorScheme="blue"
rightIcon={<FaArrowRight />}
isDisabled={currentPrimaryPage === totalPrimaryInstrumentPages}
bg={currentPrimaryPage === totalPrimaryInstrumentPages ? "gray.300" : "blue.500"}
_hover={{
 bg: currentPrimaryPage === totalPrimaryInstrumentPages
   ? "gray.300"
   : "blue.400",
}}
>
Next
</Button>
</Flex>
)}
 </Box>

):""}
               
                  {/* Pagination Controls */}
                  {/* {totalPages > 1 && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mt="6"
                    >
                      <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                        isDisabled={currentPage === 1}
                        bg={currentPage === 1 ? "gray.300" : "blue.500"}
                        _hover={{
                          bg: currentPage === 1 ? "gray.300" : "blue.400",
                        }}
                      >
                        Previous
                      </Button>

                      <Text fontWeight="bold" color="#244c9c">
                        Page {currentPage} of {totalPages}
                      </Text>

                      <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                        isDisabled={currentPage === totalPages}
                        bg={
                          currentPage === totalPages ? "gray.300" : "blue.500"
                        }
                        _hover={{
                          bg:
                            currentPage === totalPages
                              ? "gray.300"
                              : "blue.400",
                        }}
                      >
                        Next
                      </Button>
                    </Flex>
                  )} */}
                </>

                {/* {currentInstruments.length >= 10 || totalPages > 1 ? (
                  <Flex justifyContent="space-between" mt="4">
                    {currentPage === 1 ? (
                      <Button
                        size="sm"
                        colorScheme="gray"
                        leftIcon={<FaArrowLeft />}
                      >
                        Previous
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                      >
                        Previous
                      </Button>
                    )}
                    <Text>
                      Page {currentPage} of {totalPages}
                    </Text>
                    {currentPage === totalPages ? (
                      <Button
                        size="sm"
                        colorScheme="gray"
                        rightIcon={<FaArrowRight />}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                      >
                        Next
                      </Button>
                    )}
                  </Flex>
                ) : (
                  ""
                )} */}
              </TabPanel>

              {/* <==========================================Client Panel==================================> */}
              <TabPanel>
                <Table
                  variant="simple"
                  size="sm"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  boxShadow="sm"
                  bg="white"
                >
                  {/* Table header */}
                  <Thead>
                    <Tr>
                      <Th fontSize="sm" fontWeight="bold">
                        #
                      </Th>
                      <Th
                        fontSize="sm"
                        textTransform="capitalize"
                        fontWeight="bold"
                      >
                        Client ID
                      </Th>
                      <Th
                        fontSize="sm"
                        textTransform="capitalize"
                        fontWeight="bold"
                      >
                        Client Name
                      </Th>
                      <Th
                        fontSize="sm"
                        textTransform="capitalize"
                        fontWeight="bold"
                      >
                        Order Status
                      </Th>
                      <Th>
                        <InputGroup size="sm" mt={2}>
                          <InputLeftElement pointerEvents="none">
                            <FaSearch color="gray.500" />
                          </InputLeftElement>
                          <Input
                            placeholder="Search client"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            borderRadius="md"
                            focusBorderColor="blue.500"
                            bg="gray.50"
                          />
                        </InputGroup>
                      </Th>
                    </Tr>
                  </Thead>
                  {/* Table body */}
                  <Tbody>
        {currentInstrumentsClient.length > 0 ? (
          currentInstrumentsClient.map((client, index) => {
            // const clientKey = `${id}_${client.ID}`;
            // const isMessageSent = sentMessages[clientKey];
            const clientKey = `${id}_${client.ID}`;
            const isMessageSent = sentMessages[clientKey];
            const isRowLoading = rowLoading[client.ID]; // Check loading state for the specific client
      
            return (
              <Tr key={client._id}>
                <Td>{index + 1}</Td>
                <Td>{client.centrumID}</Td>
                <Td>{client.username}</Td>
                <Td>{client.status|| "NA"}</Td>
                <Td>
                  {isMessageSent ? (
                    <Button colorScheme="gray" size="xs" isDisabled>
                      Message Sent
                    </Button>
                  ) : (
                    <Flex justifyContent="flex-end">
                      <Button
                        colorScheme="green"
                        leftIcon={<FaPlus />}
                        size="xs"
                        onClick={() => handleSendMessage(client)}
                        isLoading={isRowLoading}
                        loadingText='Message Sending...'
                        variant="outline"
                        _hover={{ bg: "green.500", color: "white" }}
                      >
                        Send Message
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            );
          })
        ) : (
          <Tr>
            <Td colSpan={4} textAlign="center" color="gray.500">
              No clients available.
            </Td>
          </Tr>
        )}
      </Tbody>
                </Table>

                {filteredClients.length > 0 && totalClientPages > 1 && (
                  <Flex justifyContent="space-between" mt="4">
                    {clientCurrentPage === 1 ? (
                      <Button
                        size="sm"
                        colorScheme="gray"
                        leftIcon={<FaArrowLeft />}
                      >
                        Previous
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          handlePageChangeClient(clientCurrentPage - 1)
                        }
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                      >
                        Previous
                      </Button>
                    )}
                    <Text>
                      Page {clientCurrentPage} of {totalClientPages}
                    </Text>
                    {clientCurrentPage === totalClientPages ? (
                      <Button
                        size="sm"
                        colorScheme="gray"
                        rightIcon={<FaArrowRight />}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          handlePageChangeClient(clientCurrentPage + 1)
                        }
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                      >
                        Next
                      </Button>
                    )}
                  </Flex>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mt={{ base: "5", md: "0" }}
        >
          <Text fontSize="xl" fontWeight="bold" mb="4" color="blue.500">
            Basket Info: {data.title}
          </Text>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <strong>Basket Name:</strong>
                </Td>
                <Td>{data.title}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Description:</strong>
                </Td>
                <Td>
                  {data.description.length > 1000
                    ? `${data.description.substring(0, 1000)}...`
                    : data.description}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Rational:</strong>
                </Td>
                <Td>
                  {rationalLines.map((line, index) => (
                    <Box key={index} display="flex" mb={4}>
                      {/* <Text as="span" mr={2} fontSize="md" color="gray.700"> */}
                      <Heading as="h5" size="sm" mr={2}>
                        •
                      </Heading>
                      {/* </Text> */}
                      <Text fontSize="sm" color="gray.700">
                        {line}
                      </Text>
                    </Box>
                  ))}
                </Td>
              </Tr>

              <Tr>
                <Td>
                  <strong>Expiry Date:</strong>
                </Td>
                <Td>{data.expiryTime}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Creation Date:</strong>
                </Td>
                <Td>{data.creationTime}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Exchange Type:</strong>
                </Td>
                <Td>{data.exchangeType}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Minimum Fund Required:</strong>
                </Td>
                <Td>{totalFundRequired}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Research Head Approval:</strong>
                </Td>
                <Td>
                  {status === "PENDING" && (
                    <Text
                      bg="gray.200"
                      p="1"
                      px="3"
                      width={{ base: "90%", md: "40%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Pending
                    </Text>
                  )}
                  {status === "APPROVED" && (
                    <Text
                      bg="green.200"
                      p="1"
                      px="3"
                      width={{ base: "50%", md: "30%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Yes
                    </Text>
                  )}
                  {status === "REJECTED" && (
                    <Text
                      bg="red.200"
                      p="1"
                      px="3"
                      width={{ base: "50%", md: "30%", lg: "30%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      No
                    </Text>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Box>
  );
}
