import axios from 'axios';
import { CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, CLIENTS_FAILURE, GET_BASKET_CLIENTS_SUCCESS } from '../actionTypes';

export const clientsRequest = () => ({
  type: CLIENTS_REQUEST,
});

export const getClientsSuccess = (payload) => ({
  type: GET_CLIENTS_SUCCESS,
  payload,
});

export const getBasketClientsSuccess = (payload) => ({
  type: GET_BASKET_CLIENTS_SUCCESS,
  payload,
});

export const clientsFailure = (error) => ({
  type: CLIENTS_FAILURE,
  payload: error,
});


let url=process.env.REACT_APP_NewURL
// let V3_URL = process.env.REACT_APP_VTHREE
let V3_URL = process.env.REACT_APP_VTHREE_AWS

export const fetchDealerClients = (token) => (dispatch) => {
  dispatch(clientsRequest());

  axios.get(`${V3_URL}centrum-galaxc/user/v3/team/client-list`, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  })
  .then((response) => {

  if(response.data.status==="success"){

    dispatch(getClientsSuccess(response.data.data.clientList));
  }
  })
  .catch((error) => {
    console.error('Error fetching clients:', error);
    dispatch(clientsFailure(error.message));
  });
};

export const fetchBasketClients=(id,token)=>(dispatch)=>{
  axios.post(`${V3_URL}centrum-galaxc/user/v3/team/basket-clients?basketID=${id}`,{}, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  })
  .then((response) => {

  if(response.data.status==="success"){

    dispatch(getBasketClientsSuccess(response.data.data.clientData));
  }
  })
  .catch((error) => {
    console.error('Error fetching fetchBasketClients:', error);
    dispatch(clientsFailure(error.message));
  });
}


export const sendMessageToClient = (sendData, token) => async () => {
  try {
  
    const response = await axios.post(
      `${V3_URL}centrum-galaxc/user/v3/team/massage-client?clientID=${sendData.clientID}&basketID=${sendData.basketID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in sendMessageToClient:", error.response?.data || error.message);
    throw error;
  }
};



export const fetchAllClientLists = (token) => (dispatch) => {

  return axios.get(`${url}app/client/clients-list`, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  });
};

// <=============================Admin================================>

export const fetchAllClients = (token) => (dispatch) => {
  dispatch(clientsRequest());

  axios.get(`${V3_URL}centrum-galaxc/user/v3/team/dealer-client-list`, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  })
  .then((response) => {

  if(response.data.status==='success'){
    dispatch(getClientsSuccess(response.data.data.dealerClientList));
  }
  })
  .catch((error) => {
    
    dispatch(clientsFailure(error.message));
  });
};


export const fetchAllBasketClientList = (basket_id, token) => (dispatch) => {

  return axios.get(`${url}basket-client-list/admin/baskets?basket_id=${basket_id}`, {
    headers: { "Access-Token": token },
  });
};


// POST postClient
export const addClient = (sendData, token) => async (dispatch) => {
  dispatch(clientsRequest);

  try {
    const response = await axios.post(`${V3_URL}centrum-galaxc/user/v3/team/create-client`, sendData, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });
 
    return response.data;
  } catch (error) {
    console.log(error,"postClient error")
   
  }
};