// theme/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',  // or 'dark'
    useSystemColorMode: false,  // Set to true if you want to follow the system's theme
  },
  colors: {
    primary: '#244c9c',
  },
});

export default theme;
