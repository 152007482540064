import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  useToast,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Components/Admin/Navbar"
import Cookies from "js-cookie";
import axios from "axios";
import { FaArrowLeft, FaArrowRight, FaPlus, FaBell, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllBasketClientList,
  fetchAllClients,
  fetchBasketClients,
  // fetchBasketClientList,
  // fetchClients,
  postAddToBasket,
} from "../../Redux/clientReducer/action";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { fetchLatestBasketList, fetchSingleBasketData } from "../../Redux/basketReducer/action";
import { fetchSymbols } from "../../Redux/symbolReducer/action";

export default function BasketDetails() {
  const [data, setData] = useState(null);
  const [basketClientList, setBasketClientList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [clientCurrentPage, setClientCurrentPage] = useState(1);
  const [totalFundRequired, setTotalFundRequired] = useState(0);
  const [clientId, setClientId] = useState("");
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [rejected, setRejected] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [latestInstruments, setLatestInstruments] = useState([]);
  const [primaryInstruments, setPrimaryInstruments] = useState([]);
  const [historyInstruments, setHistoryInstruments] = useState([]);
  const [pendingInstruments,setPendingInstruments] = useState([]);
  const [approvedReason,setApprovedReason] = useState("");
  const [rejectedReason,setRejectedReason] = useState("")
  
  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [currentPrimaryPage,setCurrentPrimaryPage] = useState(1)
  const [latestInstrumentPage,setLatestInstrumentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState(""); // State for search
  const [clientCurrentPage, setClientCurrentPage] = useState(1); // Current page
  const clientsPerPage = 10;

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  let { id } = useParams();
  let token = Cookies.get("login_token_admin");

  let clientData = useSelector((store) => store.clientsReducer.basketClients);
  const Symbols = useSelector((store) => store.symbolsReducer.symbols);

  if (clientData == undefined) {
    clientData = [];
  }
  // const fetchData = async () => {
  //   try {
  //     const response = await axios.post(
  //       `https://centrum.stoq.club/api/backend/get-one/baskets?basket_id=${id}`,
  //       {},
  //       {
  //         headers: { "Access-Token": token },
  //       }
  //     );

  //     if (
  //       response.data.message == "You do not Have permission to access the data"
  //     ) {
  //       Cookies.set("login_token_dealer", ``);
  //       Cookies.set("username_dealer", "");

  //       navigate("/admin");
  //     }
  //     setStatus(response.data.response.data[0].rahStatus);
  //     setData(response.data.response.data[0]);
  //   } catch (error) {
  //     toast({
  //       title: `${error.message} error`,
  //       position: "bottom",
  //       status: "error",
  //       duration: 2000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [id, token, toggle]);
  

  useEffect(()=>{
    const fetchLatestInstruments = ()=>{
      dispatch(fetchLatestBasketList(id,token))
      .then((res)=>{
       
        if(res.status==='success'){
  
          setLatestInstruments(res.data.basketList[0].concerns)
        }
      })
      .catch((error)=>{
        console.log(error,"error fetchLatestInstruments")
      })
    }
    fetchLatestInstruments()
  },[id, token, dispatch, toggle])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchSingleBasketData(id, token));

        if (response.detail === "Token has expired") {
          Cookies.set("login_token_admin", "");
          Cookies.set("username_admin", "");
        }

        if (
          response.data.message ===
          "You do not Have permission to access the data"
        ) {
          Cookies.set("login_token_admin", "");
          Cookies.set("username_admin", "");
          navigate("/admin");
        }

        const basket = response.data.basketList[0];
       
        const concerns = basket.concerns;

      let rejectedReason = "";
      let approvedReason = "";

        let isApproved = false;
        let isRejected = false;
        let isPending = false;
        let canEdit = true;

        // // Filter for instruments with orderType "Entry" and store the reason
        // const entryInstruments = instruments.filter(
        //   (instrument) => instrument.orderType === "Entry"
        // );

        // if (entryInstruments.length > 0) {
        //   setReason(entryInstruments[0].reason); // Update this logic if you need to handle multiple reasons
        // }

        // Variables to hold the categorized instruments
        let latestInstruments = [];
        let primaryInstruments = [];
        let historyInstruments = [];
        let pendingInstruments = [];
  
  
       // Check instruments of type "Primary" and raHeadStatus
       concerns.forEach((concern) => {

        const reason = concern.reason; // Extract reason from the current concern
        const status = concern.status
        if(reason){

          // Add `reason` to each instrument
          const instrumentsWithReason = concern.instruments.map((instrument) => ({
            ...instrument,
            reason, // Add the `reason` field to each instrument
            status // Add the status field to the each instrument
          }));
  
          // Add to historyInstruments
          historyInstruments = historyInstruments.concat(instrumentsWithReason);
  
          // Check if instrument is PRIMARY
          if (concern.instrumentType === "PRIMARY") {
            primaryInstruments = primaryInstruments.concat(instrumentsWithReason);
          }
  
          // Check for latest APPROVED concerns
          if (concern.status === "APPROVED") {
            if (
              !latestInstruments.length ||
              new Date(concern.time) >
                new Date(latestInstruments[0]?.time || 0)
            ) {
              latestInstruments = instrumentsWithReason;
            }
          }
        }

        // Check for PENDING concerns
        if (concern.status === "") {
          pendingInstruments = pendingInstruments.concat(concern.instruments);
        }


        if (concern.instrumentType === "PRIMARY") {
          if (concern.status === "APPROVED") {
            isApproved = true;
          } else if (concern.status === "") {
            isPending = true;
            // isApprovalRequired = true; // Set true if any raHeadStatus is empty
          } else if (concern.status === "REJECTED") {
            isRejected = true;
          }
        }
      });

     

      // Set the approval pending state
      // setIsApprovalPending(isApprovalPending);

      // Setting the basket status based on the instruments
      if (isRejected) {
        setStatus("REJECTED");
        // setRejected(true);
        // setStatusUpdated(false);
      } else if (isPending) {
        setStatus("PENDING");
        // setRejected(false);
      } else if (isApproved) {
        setStatus("APPROVED");
        // setRejected(false);
        // setStatusUpdated(false);
      }

       // Save categorized data
      //  setLatestInstruments(latestInstruments); // Update with latest APPROVED instruments
       setPrimaryInstruments(primaryInstruments); // Update with all PRIMARY instruments
       setHistoryInstruments(historyInstruments); // Update with all instruments
       setPendingInstruments(pendingInstruments); // Update with PENDING instruments

        setData(basket);
      } catch (error) {
        console.log(error, "Error");
      }
    };

    fetchData();
  }, [id, token, dispatch, toggle]);

  useEffect(()=>{
    dispatch(fetchBasketClients(id,token))
  },[id,token])





  // useEffect(() => {
  //   dispatch(fetchAllBasketClientList(id, token))
  //     .then((res) => {
    
  //       setBasketClientList(res.data.response);
  //     })
  //     .catch((error) => {
  //       console.log(error, "fetchBasketClientList Error ");
  //       if(error.response.data.detail!=="There is no client with this basket"){

  //         toast({
  //           title: `${error.message} error`,
  //           position: "bottom",
  //           status: "error",
  //           duration: 2000,
  //           isClosable: true,
  //         })
  //       }
  //     });
  // }, [id, token, toggle]);

  useEffect(() => {
    dispatch(fetchAllClients(token));
    dispatch(fetchSymbols(token));
  }, [dispatch, token, toggle]);

  useEffect(() => {
    if (latestInstruments.length>0) {
      const total = latestInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
      setTotalFundRequired(Math.ceil(total));
    }else if (primaryInstruments.length>0){
      const total = primaryInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
    
      setTotalFundRequired(Math.ceil(total));
    }else {
      const total = pendingInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
    
      setTotalFundRequired(Math.ceil(total));
    }
  }, [data]);

  if (!data) {
    return (
      <Box>
        <Navbar />
        <Text>Loading....</Text>
      </Box>
    );
  }

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const instruments = Object.values(data.concerns);
  const totalPages = Math.ceil(instruments.length / itemsPerPage);

  const totalClientPages = Math.ceil(clientData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChangeClient = (newPage) => {
    setClientCurrentPage(newPage);
  };
  let basketHistory = true;
  if (historyInstruments.length == primaryInstruments.length) {
    basketHistory = false;
  }


  const instrumentStartIndex = (currentPage - 1) * itemsPerPage;
  const instrumentEndIndex = instrumentStartIndex + itemsPerPage;
  const currentInstruments = instruments.slice(
    instrumentStartIndex,
    instrumentEndIndex
  );

  const isClientInBasket = (clientId) => {
    if (!basketClientList || basketClientList.length === 0) return false;

    return basketClientList.some((client) => client.clientId === clientId);
  };

  const clientStartIndex = (clientCurrentPage - 1) * itemsPerPage;
  const clientEndIndex = clientStartIndex + itemsPerPage;


  const currentInstrumentsClient = clientData
    .slice(clientStartIndex, clientEndIndex)
    .filter((client) => isClientInBasket(client.ID));


  const calculateQuantile = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const stopLoss = instrumentListData.stopLoss;
    const takeProfit = instrumentListData.takeProfit;
    const cmp = instrumentListData.cmp;
    const quantile = (cmp - stopLoss) / (takeProfit - stopLoss);
    // Convert quantile to percentage
    const quantilePercentage = quantile * 100;
    let Result = Math.floor(quantilePercentage);

    return `${Result}%`;
  };

  const calculateFundREquired = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const cmp = instrumentListData.creationPrice;
    const fundRequired = (cmp * qty);

    return fundRequired;
  };

  const handleOrderStatus = (ClientId) => {
    const orderStauts = basketClientList.filter(
      (ele) => ele.clientId == ClientId
    );

    if (orderStauts.length > 0) {
      return orderStauts[0].orderStatus;
    }
  };

  const InstrumentRow = ({ instrument }) => {
    const quantileValue = parseFloat(calculateQuantile(instrument));

    let badgeText;
    let badgeColor;

    if (quantileValue > 100) {
      badgeText = "Book Profit";
      badgeColor = "green";
    } else if (quantileValue > 0 && quantileValue <= 100) {
      badgeText = "Intrade";
      badgeColor = "green";
    } else {
      badgeText = "Stop Loss";
      badgeColor = "red";
    }

    return <Badge colorScheme={badgeColor}>{badgeText}</Badge>;
  };

  const handleSymbolName = (symbol) => {
    if (symbol !== "" && symbol !== null) {
      let filterSymbolName = Symbols.filter((ele) => ele.instrument == symbol);
      let result = filterSymbolName[0];

      if (result !== undefined) {
        return result.name;
      }
    }

    return symbol; // Return an empty string if the symbol is not found or invalid
  };
  const handleBackClick = () => {
    navigate("/admin/dashboard");
  };

  
  // <==========================================History PAgination==============================>
    // const itemsPerPage = 10;
    // Calculate total pages
    const totalHistoryPages = Math.ceil(historyInstruments.length / itemsPerPage);

    // Calculate start and end index for current page
    const startHistoryIndex = (currentHistoryPage - 1) * itemsPerPage;
    const endHistoryIndex = startHistoryIndex + itemsPerPage;
  
    // Slice data for current page
    const currentHistoryData = historyInstruments.slice(startHistoryIndex, endHistoryIndex);
  
    // Handle page change
    const handleHistoryPageChange = (newPage) => {
      if (newPage > 0 && newPage <= totalHistoryPages) {
        setCurrentHistoryPage(newPage);
      }
    };
  
    // <=================================== Latest Instruments PAgination =================>
  const totalLatestInstrumentPages = Math.ceil(latestInstruments.length/ itemsPerPage);
    // Calculate start and end index for current page
    const startLatestIndex = (latestInstrumentPage - 1) * itemsPerPage;
    const endLatestIndex = startLatestIndex + itemsPerPage;
  
    // Slice data from current Page
  
    const currentLatestData = latestInstruments.slice(startLatestIndex,endLatestIndex);
  
    //Handle Page chanfe 
    const handleLatestInstrumentPageChange = (newPage) => {
      if(newPage>0 && newPage <= totalLatestInstrumentPages){
        setLatestInstrumentPage(newPage)
      }
    }
  
    // <======================== Primary Instruments PAgination ============================>
      const totalPrimaryInstrumentPages = Math.ceil(primaryInstruments.length/ itemsPerPage);
    
    // Calculate start and end index for current page
    const startPrimaryIndex = (currentPrimaryPage - 1) * itemsPerPage;
    const endPrimaryIndex = startPrimaryIndex + itemsPerPage;
  
     // Slice data from current Page
  
     const currentPrimaryData = primaryInstruments.slice(startPrimaryIndex,endPrimaryIndex);
  
  
     //Handle Page chanfe 
    const handlePrimaryInstrumentPageChange = (newPage) => {
      if(newPage>0 && newPage <= totalPrimaryInstrumentPages){
        setCurrentPrimaryPage(newPage)
      }
    }



    const formateDate=(date)=>{
      let options = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
      };
    
      let dateObj = new Date(date);
      
      return  dateObj.toLocaleDateString("en-US",options).replace(',','')
    }



 // Filter clients based on search input
 const filteredClients = clientData.filter(
  (client) =>
    client.centrumID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.username.toLowerCase().includes(searchTerm.toLowerCase())
);

// Pagination logic
const totalBasketClientPages = Math.ceil(filteredClients.length / clientsPerPage);

const startIndexClient = (clientCurrentPage - 1) * clientsPerPage;
const currentClients = filteredClients.slice(startIndexClient, startIndexClient + clientsPerPage);

// Handle page change

const handlePageClientBasketChange = (newPage) => {
  setClientCurrentPage(newPage);
};
    
  return (
    <Box>
      <Navbar />

      <Flex align="center" justify="flex-start" mt={2} ml={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={8} />}
          aria-label="Go back"
          onClick={handleBackClick}
          size="lg"
          isRound
          bgColor="#244c9c"
          color="white"
          _hover={{
            transform: "scale(1.2)",
            boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
            bgColor: "#1a3a6b", // Darker shade for hover
          }}
          transition="all 0.3s ease"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.2)"
          _active={{
            transform: "scale(0.95)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Flex>

      <Flex
        direction={{ base: "column", md: "column", lg: "row" }}
        mt="10"
        m={{ base: "2", md: "5" }}
        gap={"10px"}
      >
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mb={{ base: "5", md: "0" }}
          overflowX="auto"
        >
          <Tabs onChange={(index) => setActiveTab(index)} variant="enclosed">
            <TabList>
              <Tab fontWeight="bold">Compositions</Tab>
              <Tab fontWeight="bold">Client</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                 <>
                  {/* Table 1: pending Instruments  List */}
                  {pendingInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Edit Instrument List
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pendingInstruments.map((instrument, index) => (
                            <Tr
                              key={index}
                              // bg={instrument.orderType === "Entry" ? "green.200" : "red.200"}
                            >
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.cmp}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: latestInstruments  List */}
                  {latestInstruments.length > 0 &&basketHistory ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        New Basket
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                             <Th>Creation Date</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentLatestData.map((instrument, index) => (
                            <Tr key={index}>
                              <Td>{startLatestIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.cmp}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                               <Td whiteSpace="nowrap">{formateDate(instrument.createdAt)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>

                       {/* Pagination Controls */}
          {totalLatestInstrumentPages > 1 && (
            <Flex justifyContent="space-between" alignItems="center" mt="6">
              <Button
                onClick={() => handleLatestInstrumentPageChange(latestInstrumentPage - 1)}
                size="sm"
                colorScheme="blue"
                leftIcon={<FaArrowLeft />}
                isDisabled={latestInstrumentPage === 1}
                bg={latestInstrumentPage === 1 ? "gray.300" : "blue.500"}
                _hover={{
                  bg: latestInstrumentPage === 1 ? "gray.300" : "blue.400",
                }}
              >
                Previous
              </Button>

              <Text fontWeight="bold" color="#244c9c">
                Page {latestInstrumentPage} of {totalLatestInstrumentPages}
              </Text>

              <Button
                onClick={() => handleLatestInstrumentPageChange(latestInstrumentPage + 1)}
                size="sm"
                colorScheme="blue"
                rightIcon={<FaArrowRight />}
                isDisabled={latestInstrumentPage === totalLatestInstrumentPages}
                bg={latestInstrumentPage === totalLatestInstrumentPages ? "gray.300" : "blue.500"}
                _hover={{
                  bg: latestInstrumentPage === totalLatestInstrumentPages
                    ? "gray.300"
                    : "blue.400",
                }}
              >
                Next
              </Button>
            </Flex>
          )}
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: History Instruments List */}
                  {basketHistory && historyInstruments.length > 0 ? (
        <Box>
          <Text fontSize="2xl" mt="10" mb="6" fontWeight="bold" color="#244c9c">
            Basket History
          </Text>
          <Table size="md">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Script Name</Th>
                <Th>Quantity</Th>
                <Th>Stop Loss</Th>
                <Th>CMP</Th>
                <Th>Take Profit</Th>
                <Th>Order Type</Th>
                <Th>RaHead Approval</Th>
                <Th>Reason</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentHistoryData.reverse().map((instrument, index) => (
                <Tr key={index}>
                  <Td>{startHistoryIndex + index + 1}</Td>
                  <Td>{handleSymbolName(instrument.instrument)}</Td>
                  <Td>{instrument.quantity}</Td>
                  <Td>{instrument.stopLoss}</Td>
                  <Td>{instrument.cmp}</Td>
                  <Td>{instrument.takeProfit}</Td>
                  <Td
                    fontWeight="bold"
                    color={
                      instrument.orderType === "ENTRY"
                        ? "green.200"
                        : "red.200"
                    }
                  >
                    {instrument.orderType}
                  </Td>
                  <Td
                    fontWeight="bold"
                    color={
                      instrument.status === "APPROVED"
                        ? "green.200"
                        : "red.200"
                    }
                  >
                    {instrument.status}
                  </Td>

                   <Td
                                                    wordBreak="break-word"
                                                    whiteSpace="nowrap"
                                                    maxWidth="550px"
                                                    padding="4"
                                                    // border="1px solid #ccc"
                                                    backgroundColor="gray.50"
                                                  >
                                                    {instrument.reason === "" ? (
                                                      <Text color="gray.500" fontStyle="italic">
                                                        Pending
                                                      </Text>
                                                    ) : (
                                                      <Text fontSize="sm" color="blackAlpha.800">{instrument.reason}</Text>
                                                    )}
                                                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Pagination Controls */}
          {totalHistoryPages > 1 && (
            <Flex justifyContent="space-between" alignItems="center" mt="6">
              <Button
                onClick={() => handleHistoryPageChange(currentHistoryPage - 1)}
                size="sm"
                colorScheme="blue"
                leftIcon={<FaArrowLeft />}
                isDisabled={currentHistoryPage === 1}
                bg={currentHistoryPage === 1 ? "gray.300" : "blue.500"}
                _hover={{
                  bg: currentHistoryPage === 1 ? "gray.300" : "blue.400",
                }}
              >
                Previous
              </Button>

              <Text fontWeight="bold" color="#244c9c">
                Page {currentHistoryPage} of {totalHistoryPages}
              </Text>

              <Button
                onClick={() => handleHistoryPageChange(currentHistoryPage + 1)}
                size="sm"
                colorScheme="blue"
                rightIcon={<FaArrowRight />}
                isDisabled={currentHistoryPage === totalHistoryPages}
                bg={currentHistoryPage === totalHistoryPages ? "gray.300" : "blue.500"}
                _hover={{
                  bg: currentHistoryPage === totalHistoryPages
                    ? "gray.300"
                    : "blue.400",
                }}
              >
                Next
              </Button>
            </Flex>
          )}
        </Box>
      ) : (
       ""
      )}
                  {/* Table 3: Primary Instrument List */}
{currentPrimaryData.length>0? (
   <Box mt={10}>
   {
   primaryInstruments.length > 0 &&basketHistory || pendingInstruments.length>0&& primaryInstruments.length > 0 ? (
     <Text
       fontSize="2xl"
       mt="10"
       mb="6"
       fontWeight="bold"
       color="#244c9c"
     >
       Primary Instrument List
     </Text>
   ) : (
     ""
   )}

   <Table size="md">
     <Thead>
       <Tr>
         <Th>#</Th>
         <Th>Script Name</Th>
         <Th>Quantity</Th>
         <Th>Stop Loss</Th>
         <Th>CMP</Th>
         <Th>Take Profit</Th>
         <Th>Order Type</Th>
         <Th>Reason</Th>
       </Tr>
     </Thead>
     <Tbody>
       {currentPrimaryData.map((instrument, index) => (
         <Tr key={index}>
           <Td>{startPrimaryIndex + index + 1}</Td>
           <Td>{handleSymbolName(instrument.instrument)}</Td>
           <Td>{instrument.quantity}</Td>
           <Td>{instrument.stopLoss}</Td>
           <Td>{instrument.cmp}</Td>
           <Td>{instrument.takeProfit}</Td>
           <Td
             fontWeight="bold"
             color={
               instrument.orderType === "ENTRY"
                 ? "green.200"
                 : "red.200"
             }
           >
             {instrument.orderType}
           </Td>
            <Td
                                             wordBreak="break-word"
                                             whiteSpace="nowrap"
                                             maxWidth="550px"
                                             padding="4"
                                             // border="1px solid #ccc"
                                             backgroundColor="gray.50"
                                           >
                                             {instrument.reason === "" ? (
                                               <Text color="gray.500" fontStyle="italic">
                                                 Pending
                                               </Text>
                                             ) : (
                                               <Text fontSize="sm" color="blackAlpha.800">{instrument.reason}</Text>
                                             )}
                                           </Td>
         </Tr>
       ))}
     </Tbody>
   </Table>


    {/* Pagination Controls */}
{totalPrimaryInstrumentPages > 1 && (
<Flex justifyContent="space-between" alignItems="center" mt="6">
<Button
onClick={() => handlePrimaryInstrumentPageChange(currentPrimaryPage - 1)}
size="sm"
colorScheme="blue"
leftIcon={<FaArrowLeft />}
isDisabled={currentPrimaryPage === 1}
bg={currentPrimaryPage === 1 ? "gray.300" : "blue.500"}
_hover={{
 bg: currentPrimaryPage === 1 ? "gray.300" : "blue.400",
}}
>
Previous
</Button>

<Text fontWeight="bold" color="#244c9c">
Page {currentPrimaryPage} of {totalPrimaryInstrumentPages}
</Text>

<Button
onClick={() => handlePrimaryInstrumentPageChange(currentPrimaryPage + 1)}
size="sm"
colorScheme="blue"
rightIcon={<FaArrowRight />}
isDisabled={currentPrimaryPage === totalPrimaryInstrumentPages}
bg={currentPrimaryPage === totalPrimaryInstrumentPages ? "gray.300" : "blue.500"}
_hover={{
 bg: currentPrimaryPage === totalPrimaryInstrumentPages
   ? "gray.300"
   : "blue.400",
}}
>
Next
</Button>
</Flex>
)}
 </Box>

):""}
               
                  {/* Pagination Controls */}
                  {/* {totalPages > 1 && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mt="6"
                    >
                      <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                        isDisabled={currentPage === 1}
                        bg={currentPage === 1 ? "gray.300" : "blue.500"}
                        _hover={{
                          bg: currentPage === 1 ? "gray.300" : "blue.400",
                        }}
                      >
                        Previous
                      </Button>

                      <Text fontWeight="bold" color="#244c9c">
                        Page {currentPage} of {totalPages}
                      </Text>

                      <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                        isDisabled={currentPage === totalPages}
                        bg={
                          currentPage === totalPages ? "gray.300" : "blue.500"
                        }
                        _hover={{
                          bg:
                            currentPage === totalPages
                              ? "gray.300"
                              : "blue.400",
                        }}
                      >
                        Next
                      </Button>
                    </Flex>
                  )} */}
                </>
              </TabPanel>

              {/* <==========================================Client Panel==================================> */}
              <TabPanel>
                {/* Table */}
      <Table variant="simple" colorScheme="teal" size="sm">
        {currentClients.length > 0 && (
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th textTransform="capitalize">Client ID</Th>
              <Th>Name</Th>
              <Th>
                                      <InputGroup size="sm" mt={2}>
                                        <InputLeftElement pointerEvents="none">
                                          <FaSearch color="gray.500" />
                                        </InputLeftElement>
                                        <Input
                                          placeholder="Search client"
                                          value={searchTerm}
                                          onChange={(e) => setSearchTerm(e.target.value)}
                                          borderRadius="md"
                                          focusBorderColor="blue.500"
                                          bg="gray.50"
                                        />
                                      </InputGroup>
                                    </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {currentClients.length > 0 ? (
            currentClients.map((client, index) => (
              <Tr key={index}>
                <Td>{startIndexClient + index + 1}</Td>
                <Td>{client.centrumID}</Td>
                <Td color="blue.500" fontWeight="bold">
                  {client.username}
                </Td>
                <Td>Invested</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="3" textAlign="center">
                No Client has been assigned
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* Pagination Controls */}
      {filteredClients.length > clientsPerPage && (
        <Flex justifyContent="space-between" mt="4">
          <Button
            size="sm"
            colorScheme={clientCurrentPage === 1 ? "gray" : "blue"}
            onClick={() => handlePageClientBasketChange(clientCurrentPage - 1)}
            isDisabled={clientCurrentPage === 1}
            leftIcon={<FaArrowLeft />}
          >
            Previous
          </Button>
          <Text>
            Page {clientCurrentPage} of {totalBasketClientPages}
          </Text>
          <Button
            size="sm"
            colorScheme={clientCurrentPage === totalBasketClientPages ? "gray" : "blue"}
            onClick={() => handlePageClientBasketChange(clientCurrentPage + 1)}
            isDisabled={clientCurrentPage === totalBasketClientPages}
            rightIcon={<FaArrowRight />}
          >
            Next
          </Button>
        </Flex>
      )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mt={{ base: "5", md: "0" }}
        >
          <Text fontSize="xl" fontWeight="bold" mb="4" color="blue.500">
            Basket Info: {data.title}
          </Text>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <strong>Basket Name:</strong>
                </Td>
                <Td>{data.title}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Description:</strong>
                </Td>
                <Td>{data.description}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Rational:</strong>
                </Td>
                <Td>{data.rationale}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Expiry Date:</strong>
                </Td>
                <Td>{data.expiryTime}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Creation Date:</strong>
                </Td>
                <Td>{data.creationTime}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Exchange Type:</strong>
                </Td>
                <Td>{data.exchangeType}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Minimum Fund Required:</strong>
                </Td>
                <Td>{totalFundRequired}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Research Head Approval:</strong>
                </Td>
                <Td>
                  {status === "PENDING" && (
                    <Text
                      bg="gray.200"
                      p="1"
                      px="3"
                      width={{ base: "80%", md: "50%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Pending
                    </Text>
                  )}
                  {status === "APPROVED" && (
                    <Text
                      bg="green.200"
                      p="1"
                      px="3"
                      width={{ base: "80%", md: "50%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Approved
                    </Text>
                  )}
                  {status === "REJECTED" && (
                    <Text
                      bg="red.200"
                      p="1"
                      px="3"
                      width={{ base: "80%", md: "50%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Rejected
                    </Text>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Box>
  );
}
