import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const CreateClientModal = ({ isOpen, onClose, formData, handleChange, handleSubmit, loading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Client</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="username" mb={4}>
            <FormLabel>Username</FormLabel>
            <Input
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Enter username"
            />
          </FormControl>

          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
            />
          </FormControl>

          <FormControl id="centrumID" mb={4}>
            <FormLabel>Centrum ID</FormLabel>
            <Input
              name="centrumID"
              value={formData.centrumID}
              onChange={handleChange}
              placeholder="Enter Centrum ID"
            />
          </FormControl>

          <FormControl id="number" mb={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              name="number"
              value={formData.number}
              onChange={handleChange}
              placeholder="Enter phone number"
            />
          </FormControl>

          <FormControl id="email" mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isLoading={loading}  loadingText='Submitting'  >
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateClientModal;
