import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Switch,
  IconButton,
  Heading,
  useDisclosure,
  Input,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  Drawer,
  DrawerBody,
  FormControl,
  FormLabel,
  DrawerCloseButton,
  Spacer,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Components/Research-Analyst/Navbar";
import Cookies from "js-cookie";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ArrowBackIcon, DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchSymbols } from "../../Redux/symbolReducer/action";
import { MdArrowDropDown } from "react-icons/md";
import {
  editBasketData,
  fetchLatestBasketList,
  fetchSingleBasketData,
} from "../../Redux/basketReducer/action";
import ScriptDropdown from "../../Components/Research-Analyst/ScriptDropdown";

const clientData = [
  { id: 1, name: "Hemant" },
  { id: 2, name: "Amit" },
  { id: 3, name: "Priya" },
  { id: 4, name: "Rahul" },
  { id: 5, name: "Sneha" },
  { id: 6, name: "Vikram" },
  { id: 7, name: "Anjali" },
  { id: 8, name: "Rohit" },
  { id: 9, name: "Nisha" },
  { id: 10, name: "Arjun" },
  { id: 11, name: "Meena" },
  { id: 12, name: "Suresh" },
  { id: 13, name: "Kavita" },
  { id: 14, name: "Ramesh" },
  { id: 15, name: "Neha" },
  { id: 16, name: "Vijay" },
  { id: 17, name: "Sunita" },
  { id: 18, name: "Manoj" },
  { id: 19, name: "Pooja" },
  { id: 20, name: "Ajay" },
];

export default function RaBasketDetails() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientCurrentPage, setClientCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [statusUpdated, setStatusUpdated] = useState(true);
  const [editbutton, setEditbutton] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [totalFundRequired, setTotalFundRequired] = useState(0);
  const [newBasketValue, setNewBasketValue] = useState(0);
  const [killSwitch, setKillSwitch] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [latestInstruments, setLatestInstruments] = useState([]);
  const [primaryInstruments, setPrimaryInstruments] = useState([]);
  const [historyInstruments, setHistoryInstruments] = useState([]);
  const [pendingInstruments, setPendingInstruments] = useState([]);
  const [approvedReason, setApprovedReason] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [isExpired,setIsExpired]=useState(false)

  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [currentPrimaryPage, setCurrentPrimaryPage] = useState(1);
  const [latestInstrumentPage, setLatestInstrumentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [reason, setReason] = useState("");
  const [editedDate, setEditedDate] = useState("");
  const [isSaving,setIsSaving] = useState(false)

  const handleSearchInputChange = (value) => {
    setSearchTerm(value);
  };

  const handleDropdownToggle = () => {
    setShowOptions((prev) => !prev);
  };

  
  const toast = useToast();
  let { id } = useParams();
  let token = Cookies.get("login_token_ra");
  
  const Symbols = useSelector((store) => store.symbolsReducer.symbols);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  // Form state for new instrument
  const [newInstrument, setNewInstrument] = useState({
    name: "",
    instrument: "",
    orderType: "ENTRY",
    quantity: 1,
    stopLoss: 1,
    takeProfit: 0,
    // securityID: 0, // Initialize securityID
    creationPrice: 0, // Initialize creationPrice
  });

  const [editableInstruments, setEditableInstruments] = useState([]);

  useEffect(() => {
    const fetchLatestInstruments = () => {
      dispatch(fetchLatestBasketList(id, token))
        .then((res) => {
         
          if (res.status === "success") {
            setLatestInstruments(res.data.basketList[0].concerns);
            setEditableInstruments(res.data.basketList[0].concerns);
          }
        })
        .catch((error) => {
          console.log(error, "error fetchLatestInstruments");
        });
    };
    fetchLatestInstruments();
  }, [id, token, dispatch, statusUpdated]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchSingleBasketData(id, token));

        if (response.detail === "Token has expired") {
          Cookies.set("login_token_ra", "");
          Cookies.set("username_ra", "");
        }

        if (
          response.data.message ===
          "You do not Have permission to access the data"
        ) {
          Cookies.set("login_token_ra", "");
          Cookies.set("username_ra", "");
          navigate("/ra");
        }

        const basket = response.data.basketList[0];

        const concerns = basket.concerns;

        let rejectedReason = "";
        let approvedReason = "";

        let isApproved = false;
        let isRejected = false;
        let isPending = false;
        let canEdit = true;

        // Variables to hold the categorized instruments
        // let latestInstruments = [];
        let primaryInstruments = [];
        let historyInstruments = [];
        let pendingInstruments = [];

        // Iterate over concerns to classify and filter
        concerns.forEach((concern) => {
          const { reason, status, instrumentType } = concern;
          // Check for PRIMARY concerns
          if (instrumentType === "PRIMARY") {
            if (status === "REJECTED" && !rejectedReason) {
              rejectedReason = reason; // Take the first REJECTED reason
            } else if (status === "APPROVED" && !approvedReason) {
              approvedReason = reason; // Take the first APPROVED reason
            }
          }

          if (reason) {
            // Add `reason` to each instrument
            const instrumentsWithReason = concern.instruments.map(
              (instrument) => ({
                ...instrument,
                reason, // Add the `reason` field to each instrument
                status, // Add the  `status` field to each instrument
              })
            );

            // Add to historyInstruments
            historyInstruments = historyInstruments.concat(
              instrumentsWithReason
            );

            // Check if instrument is PRIMARY
            if (concern.instrumentType === "PRIMARY") {
              primaryInstruments = primaryInstruments.concat(
                instrumentsWithReason
              );
            }

            // Check for latest APPROVED concerns
            // if (concern.status === "APPROVED") {
            //   if (
            //     !latestInstruments.length ||
            //     new Date(concern.time) >
            //       new Date(latestInstruments[0]?.time || 0)
            //   ) {
            //     latestInstruments = instrumentsWithReason;
            //   }
            // }
          }

          // Check for PENDING concerns
          if (concern.status === "") {
            pendingInstruments = pendingInstruments.concat(concern.instruments);
          }

          // Set status flags based on concerns
          if (concern.instrumentType === "PRIMARY") {
            if (concern.status === "APPROVED") {
              isApproved = true;
            } else if (concern.status === "") {
              isPending = true;
              canEdit = false;
            } else if (concern.status === "REJECTED") {
              isRejected = true;
              canEdit = false;
            }
          }

          if (concern.instrumentType === "SECONDARY") {
            if (concern.status === "") {
              canEdit = false;
            }
          }
        });

        setEditbutton(canEdit);

        if (isRejected) {
          setStatus("REJECTED");
          setRejected(true);
          setStatusUpdated(false);
        } else if (isPending) {
          setStatus("PENDING");
          setRejected(false);
        } else if (isApproved) {
          setStatus("APPROVED");
          setRejected(false);
          setStatusUpdated(false);
        }

        // Save categorized data
        // setLatestInstruments(latestInstruments); // Update with latest APPROVED instruments
        setPrimaryInstruments(primaryInstruments); // Update with all PRIMARY instruments
        setHistoryInstruments(historyInstruments); // Update with all instruments
        setPendingInstruments(pendingInstruments); // Update with PENDING instruments

        setRejectedReason(rejectedReason);
        setApprovedReason(approvedReason);
        setData(basket);
      } catch (error) {
        console.log(error, "Error");
      }
    };

    fetchData();
  }, [id, token, dispatch, statusUpdated]);

  // useEffect(() => {
  //   if (data) {
  //     const total = instruments.reduce(
  //       (acc, instrument) => acc + calculateFundREquired(instrument),
  //       0
  //     );
  //     console.log(total,"Total funds")
  //     setTotalFundRequired(total);
  //   }
  // }, [data]);

  useEffect(() => {
    dispatch(fetchSymbols(token));
  }, []);

  useEffect(() => {
    if (!data?.expiryTime) return; 
  
    let currentDate = Date.now();
    let expiryDate = new Date(data.expiryTime).getTime(); 
  
    setIsExpired(currentDate >= expiryDate); 
  }, [data]);
  
  const calculateFundREquired = (instrumentListData) => {
    const qty = instrumentListData?.quantity;
    const cmp = instrumentListData?.creationPrice;
    const fundRequired = cmp * qty;

    return fundRequired;
  };

  //  useEffect(()=>{
  let FilterSymbols = Symbols.filter(
    (symbol) =>
      !historyInstruments.some((data) => data.instrument === symbol.instrument)
  );

  //  },[])

  useEffect(() => {
    if (latestInstruments.length > 0) {
      const total = latestInstruments?.reduce((acc, instrument) => {
        // Check if the instrument's orderType is 'Entry'
        if (instrument.orderType === "ENTRY") {
          return acc + calculateFundREquired(instrument);
        }
        return acc; // Skip other order types
      }, 0);

      setTotalFundRequired(Math.ceil(total));
    } else if (primaryInstruments.length > 0) {
      const total = primaryInstruments?.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );

      setTotalFundRequired(Math.ceil(total));
    } else {
      const total = pendingInstruments?.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );

      setTotalFundRequired(Math.ceil(total));
    }

    if (editableInstruments) {
      const total = editableInstruments.reduce((acc, instrument) => {
        // Check if the instrument's orderType is 'Entry'
        if (instrument.orderType === "ENTRY") {
          return acc + calculateFundREquired(instrument);
        }
        return acc; // Skip other order types
      }, 0);

      setNewBasketValue(Math.ceil(total));
    }
  }, [data, editableInstruments,latestInstruments]);
  if (!data) {
    return (
      <Box>
        <Navbar />
        <Text>Loading....</Text>
      </Box>
    );
  }

  const itemsPerPage = 10;
  // Pagination logic
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const instruments = Object.values(data.instrumentList || []);
  const totalPages = Math.ceil(instruments.length / itemsPerPage);

  // const instruments = Object.values(data.instrumentList);
  // const totalPages = Math.ceil(instruments.length / itemsPerPage);
  const totalClientPages = Math.ceil(clientData.length / itemsPerPage);
  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  const handlePageChangeClient = (newPage) => {
    setClientCurrentPage(newPage);
  };
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  const currentInstruments = instruments.slice(startIndex, endIndex);

  const clientStartIndex = (clientCurrentPage - 1) * itemsPerPage;
  const clientEndIndex = clientStartIndex + itemsPerPage;
  const currentInstrumentsClient = clientData.slice(
    clientStartIndex,
    clientEndIndex
  );

  const calculateQuantile = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const stopLoss = instrumentListData.stopLoss;
    const takeProfit = instrumentListData.takeProfit;
    const cmp = instrumentListData.cmp;
    const quantile = (cmp - stopLoss) / (takeProfit - stopLoss);
    // Convert quantile to percentage
    const quantilePercentage = quantile * 100;
    let Result = Math.floor(quantilePercentage);

    return `${Result}%`;
  };

  const calculateNewBasketFundREquired = (instrumentListData) => {
 
    const qty = Number(instrumentListData.quantity);
    const cmp = Number(instrumentListData.cmp);
    const fundRequired = Math.ceil(cmp * qty);

    return fundRequired;
  };

  const InstrumentRow = ({ instrument }) => {
    const quantileValue = parseFloat(calculateQuantile(instrument));

    let badgeText;
    let badgeColor;

    if (quantileValue > 100) {
      badgeText = "Book Profit";
      badgeColor = "green";
    } else if (quantileValue > 0 && quantileValue <= 100) {
      badgeText = "Intrade";
      badgeColor = "green";
    } else {
      badgeText = "Stop Loss";
      badgeColor = "red";
    }

    return <Badge colorScheme={badgeColor}>{badgeText}</Badge>;
  };

  const handleSymbolName = (symbol) => {
    if (symbol !== "" && symbol !== null) {
      let filterSymbolName = FilterSymbols.filter(
        (ele) => ele.instrument == symbol
      );
      let result = filterSymbolName[0];

      if (result !== undefined) {
        return result.name;
      }
    }

    return symbol; // Return an empty string if the symbol is not found or invalid
  };

  const handleUpsidePotential = (instrumentListData) => {
    let cmp = Number(instrumentListData.cmp);
    let takeProfit = Number(instrumentListData.takeProfit);

    let upsidePotential = ((takeProfit - cmp) / cmp) * 100;

    let upsidePotentialPercentage = Math.floor(upsidePotential);

    if (upsidePotentialPercentage < 0) {
      return `Target Achieved`;
    }

    return `${upsidePotentialPercentage}%`;
  };

  const handleBackClick = () => {
    navigate("/ra/dashboard");
  };

  const rationalLines =
    data && data.rationale
      ? data.rationale
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line.length > 0)
      : []; // Default to an empty array if data.rationale is undefined

  // Clone the instruments data for editing with necessary filters and remove duplicates
  const handleEditClick = () => {
    // Step 1: Create a map to track the occurrences of each instrument
    const instrumentCountMap = new Map();

    // Count occurrences of each instrument
    latestInstruments.forEach((instrument) => {
      const instrumentName = instrument.instrument;
      instrumentCountMap.set(
        instrumentName,
        (instrumentCountMap.get(instrumentName) || 0) + 1
      );
    });

    // Step 2: Filter out instruments that appear more than once (duplicates)
    const nonDuplicateInstruments = latestInstruments.filter((instrument) => {
      return instrumentCountMap.get(instrument.instrument) === 1;
    });

    // Step 3: Further filter by raHeadStatus being "APPROVED" and orderType being "Entry"
    const approvedEntryInstruments = nonDuplicateInstruments.filter(
      (instrument) => {
        return (
          instrument.status === "APPROVED" && instrument.orderType === "ENTRY"
        );
      }
    );

    // Step 4: Only set instruments if we have any valid ones left after filtering
    if (editableInstruments.length > 0) {
      const instrumentsCopy = editableInstruments.map((instrument) => ({
        instrument: instrument.instrument,
        // securityID: instrument.securityID,
        stopLoss: instrument.stopLoss,
        takeProfit: instrument.takeProfit,
        creationPrice: instrument.creationPrice,
        quantity: instrument.quantity,
        cmp:instrument.cmp,
        orderType: instrument.orderType || "", // default to empty string if orderType is missing
      }));

      setEditableInstruments(instrumentsCopy); // Set the filtered instruments
    } else {
      console.log(
        "No valid instruments found; duplicates or criteria not met."
      );
    }

    // Open the Drawer (assuming onOpen is a function to open the drawer)
    onOpen();
  };

  const handleInputChange = (index, field, value) => {
    const updatedInstruments = [...editableInstruments];
    updatedInstruments[index][field] = value;
    setEditableInstruments(updatedInstruments);
  };

  // Save changes function
  const handleSaveChanges = () => {
    setIsSaving(true)
    const instrumentsToSend = editableInstruments.filter(
      (editableInstrument) => {
        // Check if there's a matching instrument in latestInstruments

        const matchingInstrument = latestInstruments.find(
          (latestInstrument) =>
            latestInstrument.instrument === editableInstrument.instrument
        );

        // If no matching instrument is found, it's new, so include it
        if (!matchingInstrument) {
          return true;
        }

        // Check if any of the specified fields are different
        const hasChanges =
          matchingInstrument.stopLoss !== editableInstrument.stopLoss ||
          matchingInstrument.takeProfit !== editableInstrument.takeProfit ||
          matchingInstrument.orderType !== editableInstrument.orderType;

        // Include the instrument if there are changes
        return hasChanges;
      }
    );

    // console.log(latestInstruments,"latestInstruments")

    // Prepare the data to be sent
    const data = {
      instrumentList: instrumentsToSend,
    };

    // Dispatch the editBasketData action with the filtered instruments
    dispatch(editBasketData(id, token, data))
      .then((res) => {
        setIsSaving(false)
        if (res === "Network Error") {
          toast({
            title: "Error",
            description: res,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "bottom",
          });
        }
        if (res.status === "success" && res.status_code === 200) {
          toast({
            title: "Basket Updated",
            description: res.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "bottom",
          });
          setStatusUpdated(!statusUpdated);
          onClose();
        }

        if (res.status === "failed" && res.status_code === 201) {
          toast({
            title: "Error",
            description: res.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "bottom",
          });
          onClose();
        }
      })
      .catch((error) => {
        setIsSaving(false)
        console.log(error, "editBasketData error");
      });
  };

  const handleDelete = (index) => {
    const updatedInstruments = [...editableInstruments];

    // Check if 'orderType' exists, if not, create it and set it to 'Exit'
    updatedInstruments[index].orderType = "EXIT";

    // Update the state
    setEditableInstruments(updatedInstruments);
  };

  const handleAddInstrument = () => {
    // Check if all required fields are filled
    if (
      !newInstrument.quantity ||
      !newInstrument.stopLoss ||
      !newInstrument.takeProfit
    ) {
      toast({
        title: "Error",
        description: "Please fill in all fields before adding.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return; // Do not proceed if any field is empty
    }

    // Check if the instrument already exists
    const instrumentExists = editableInstruments.some(
      (instrument) => instrument.instrument === newInstrument.instrument
    );

    if (instrumentExists) {
      toast({
        title: "Error",
        description: "Script already exists.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return; // Do not proceed if the instrument already exists
    }

    // Check if takeProfit is greater than currentPrice and stopLoss is less than currentPrice
    if (newInstrument.takeProfit <= newInstrument.creationPrice) {
      toast({
        title: "Error",
        description: "Take Profit must be greater than the current price.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return; // Do not proceed if takeProfit is not valid
    }

    if (newInstrument.stopLoss >= newInstrument.creationPrice) {
      toast({
        title: "Error",
        description: "Stop Loss must be less than the current price.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return; // Do not proceed if stopLoss is not valid
    }

    // Create a new object with instrument details
    const {
      quantity,
      stopLoss,
      takeProfit,
      // securityID,
      creationPrice,
      orderType,
      instrument,
    } = newInstrument;
    const instrumentToAdd = {
      quantity,
      stopLoss,
      takeProfit,
      // securityID,
      creationPrice,
      instrument,
      orderType,
    };

    setEditableInstruments([...editableInstruments, instrumentToAdd]);
    setNewInstrument({
      name: "",
      instrument: "",
      orderType: "ENTRY",
      quantity: 1,
      stopLoss: 1,
      takeProfit: 0,
      // securityID: 0,
      creationPrice: 0,
    });
    setSearchTerm("");
    onAddClose(); // Close the add form

    // Show success toast
    toast({
      title: "Instrument Added",
      description: `${newInstrument.name} was successfully added.`,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  const isFormValid = () => {
    return (
      newInstrument.name &&
      newInstrument.quantity &&
      newInstrument.stopLoss &&
      newInstrument.takeProfit
    );
  };

  // Filter symbols based on the search term
  const filteredSymbols = FilterSymbols.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.instrument.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Filters for tables

  const newInstruments = instruments.filter(
    (instrument) =>
      instrument.instrumentType === "SECONDARY" &&
      instrument.raHeadStatus === ""
  );

  // Step 1: Create a count of instruments
  const instrumentCount = instruments.reduce((acc, instrument) => {
    acc[instrument.instrument] = (acc[instrument.instrument] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Filter out instruments based on your criteria
  const secondaryInstruments = instruments
    .filter((instrument, index, self) => {
      // Ensure uniqueness by checking if it's the first occurrence
      const isFirstOccurrence =
        index === self.findIndex((i) => i.instrument === instrument.instrument);

      // Get all instruments with the same ID
      const sameInstruments = self.filter(
        (i) => i.instrument === instrument.instrument
      );

      // Check for the presence of APPROVED and REJECTED statuses
      const hasApproved = sameInstruments.some(
        (i) => i.raHeadStatus === "APPROVED"
      );
      const hasRejected = sameInstruments.some(
        (i) => i.raHeadStatus === "REJECTED"
      );

      // Include the instrument if:
      // 1. It is APPROVED and there are no other APPROVED instruments
      // 2. It is the first occurrence of APPROVED when both statuses exist
      return (
        (instrument.raHeadStatus === "APPROVED" &&
          hasApproved &&
          hasRejected &&
          isFirstOccurrence) ||
        (instrument.raHeadStatus === "APPROVED" &&
          !hasRejected &&
          instrumentCount[instrument.instrument] === 1) ||
        (instrument.raHeadStatus === "REJECTED" &&
          !hasApproved &&
          isFirstOccurrence)
      );
    })
    .reverse();

  let newInstrumentsData = secondaryInstruments.filter(
    (instrument) => instrument.raHeadStatus === "APPROVED"
  );

  // const primaryInstruments = instruments.filter(
  //   (instrument) => instrument.instrumentType === "PRIMARY"
  // );

  let basketHistory = true;
  if (historyInstruments.length == primaryInstruments.length) {
    basketHistory = false;
  }

  const currentNewInstruments = newInstruments.slice(startIndex, endIndex);
  const currentSecondaryInstruments = secondaryInstruments.slice(
    startIndex,
    endIndex
  );
  const currentPrimaryInstruments = primaryInstruments.slice(
    startIndex,
    endIndex
  );

  const handleSelectInstrument = (item) => {
    setNewInstrument({
      ...newInstrument,
      name: item.name,
      instrument: item.instrument,
      // securityID: item.securityId,
      creationPrice:
        item.cmp === 0
          ? Math.floor(Math.random() * (500 - 10 + 1)) + 10
          : item.cmp,
    });
  };

  // <==========================================History PAgination==============================>
  // const itemsPerPage = 10;
  // Calculate total pages
  const totalHistoryPages = Math.ceil(historyInstruments.length / itemsPerPage);

  // Calculate start and end index for current page
  const startHistoryIndex = (currentHistoryPage - 1) * itemsPerPage;
  const endHistoryIndex = startHistoryIndex + itemsPerPage;

  // Slice data for current page
  const currentHistoryData = historyInstruments.slice(
    startHistoryIndex,
    endHistoryIndex
  );

  // Handle page change
  const handleHistoryPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalHistoryPages) {
      setCurrentHistoryPage(newPage);
    }
  };

  // <=================================== Latest Instruments PAgination =================>
  const totalLatestInstrumentPages = Math.ceil(
    latestInstruments.length / itemsPerPage
  );
  // Calculate start and end index for current page
  const startLatestIndex = (latestInstrumentPage - 1) * itemsPerPage;
  const endLatestIndex = startLatestIndex + itemsPerPage;

  // Slice data from current Page

  const currentLatestData = latestInstruments.slice(
    startLatestIndex,
    endLatestIndex
  );

  //Handle Page chanfe
  const handleLatestInstrumentPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalLatestInstrumentPages) {
      setLatestInstrumentPage(newPage);
    }
  };

  // <======================== Primary Instruments PAgination ============================>
  const totalPrimaryInstrumentPages = Math.ceil(
    primaryInstruments.length / itemsPerPage
  );

  // Calculate start and end index for current page
  const startPrimaryIndex = (currentPrimaryPage - 1) * itemsPerPage;
  const endPrimaryIndex = startPrimaryIndex + itemsPerPage;

  // Slice data from current Page

  const currentPrimaryData = primaryInstruments.slice(
    startPrimaryIndex,
    endPrimaryIndex
  );

  //Handle Page chanfe
  const handlePrimaryInstrumentPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPrimaryInstrumentPages) {
      setCurrentPrimaryPage(newPage);
    }
  };

  function formatDate(dateString) {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  return (
    <Box>
      <Navbar />

      <Flex align="center" justify="flex-start" mt={2} ml={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={8} />}
          aria-label="Go back"
          onClick={handleBackClick}
          size="lg"
          isRound
          bgColor="#244c9c"
          color="white"
          _hover={{
            transform: "scale(1.2)",
            boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
            bgColor: "#1a3a6b", // Darker shade for hover
          }}
          transition="all 0.3s ease"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.2)"
          _active={{
            transform: "scale(0.95)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Flex>

      <Flex
        direction={{ base: "column", md: "column", lg: "row" }}
        mt="10"
        m={{ base: "2", md: "5" }}
        gap={"10px"}
      >
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mb={{ base: "5", md: "0" }}
          overflowX="auto" // Ensure horizontal scrolling on small screens
        >
          <Tabs
            onChange={(index) => setActiveTab(index)}
            variant="enclosed"
            width={"100%"}
          >
            <TabList>
              <Tab fontWeight="bold">Compositions</Tab>
              {/* <Tab fontWeight="bold">Client</Tab>  */}
              {editbutton &&!isExpired ? (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  ml={6}
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              ) : (
                ""
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <>
                  {/* Table 1: New Instrument List */}
                  {pendingInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Edit Instrument List
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pendingInstruments.map((instrument, index) => (
                            <Tr
                              key={index}
                              // bg={instrument.orderType === "Entry" ? "green.200" : "red.200"}
                            >
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.creationPrice}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: Latest Instrument List */}
                  {latestInstruments.length > 0 && basketHistory ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        New Basket
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                            <Th>Creation Date</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentLatestData.map((instrument, index) => (
                            <Tr key={index}>
                              <Td>{startLatestIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.cmp}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                              <Td whiteSpace="nowrap">
                                {formatDate(instrument.createdAt)}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>

                      {/* Pagination Controls */}
                      {totalLatestInstrumentPages > 1 && (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="6"
                        >
                          <Button
                            onClick={() =>
                              handleLatestInstrumentPageChange(
                                latestInstrumentPage - 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaArrowLeft />}
                            isDisabled={latestInstrumentPage === 1}
                            bg={
                              latestInstrumentPage === 1
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                latestInstrumentPage === 1
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Previous
                          </Button>

                          <Text fontWeight="bold" color="#244c9c">
                            Page {latestInstrumentPage} of{" "}
                            {totalLatestInstrumentPages}
                          </Text>

                          <Button
                            onClick={() =>
                              handleLatestInstrumentPageChange(
                                latestInstrumentPage + 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            rightIcon={<FaArrowRight />}
                            isDisabled={
                              latestInstrumentPage ===
                              totalLatestInstrumentPages
                            }
                            bg={
                              latestInstrumentPage ===
                              totalLatestInstrumentPages
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                latestInstrumentPage ===
                                totalLatestInstrumentPages
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: Basket History List */}

                  {basketHistory && historyInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Basket History
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                            <Th>RaHead Approveal</Th>
                            <Th><strong>Reason</strong></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentHistoryData
                            .reverse()
                            .map((instrument, index) => (
                              <Tr key={index}>
                                <Td>{startHistoryIndex + index + 1}</Td>
                                <Td>
                                  {handleSymbolName(instrument.instrument)}
                                </Td>
                                <Td>{instrument.quantity}</Td>
                                <Td>{instrument.stopLoss}</Td>
                                <Td>{instrument.cmp}</Td>
                                <Td>{instrument.takeProfit}</Td>
                                <Td
                                  fontWeight="bold"
                                  color={
                                    instrument.orderType === "ENTRY"
                                      ? "green.200"
                                      : "red.200"
                                  }
                                >
                                  {instrument.orderType}
                                </Td>
                                {instrument.status ? (
                                  <Td
                                    fontWeight="bold"
                                    color={
                                      instrument.status === "APPROVED"
                                        ? "green.200"
                                        : "red.200"
                                    }
                                  >
                                    {instrument.status}
                                  </Td>
                                ) : (
                                  <Td fontWeight="bold" color={"gray"}>
                                    Pending
                                  </Td>
                                )}
                                <Td
                                  wordBreak="break-word"
                                  whiteSpace="nowrap"
                                  maxWidth="550px"
                                  padding="4"
                                  // border="1px solid #ccc"
                                  backgroundColor="gray.50"
                                >
                                  {instrument.reason === "" ? (
                                    <Text color="gray.500" fontStyle="italic">
                                      Pending
                                    </Text>
                                  ) : (
                                    <Text fontSize="sm" color="blackAlpha.800">{instrument.reason}</Text>
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>

                      {/* Pagination Controls */}
                      {totalHistoryPages > 1 && (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="6"
                        >
                          <Button
                            onClick={() =>
                              handleHistoryPageChange(currentHistoryPage - 1)
                            }
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaArrowLeft />}
                            isDisabled={currentHistoryPage === 1}
                            bg={
                              currentHistoryPage === 1 ? "gray.300" : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentHistoryPage === 1
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Previous
                          </Button>

                          <Text fontWeight="bold" color="#244c9c">
                            Page {currentHistoryPage} of {totalHistoryPages}
                          </Text>

                          <Button
                            onClick={() =>
                              handleHistoryPageChange(currentHistoryPage + 1)
                            }
                            size="sm"
                            colorScheme="blue"
                            rightIcon={<FaArrowRight />}
                            isDisabled={
                              currentHistoryPage === totalHistoryPages
                            }
                            bg={
                              currentHistoryPage === totalHistoryPages
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentHistoryPage === totalHistoryPages
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* Table 3: Primary Instrument List */}

                  {currentPrimaryData.length > 0 ? (
                    <Box>
                      {(primaryInstruments.length > 0 && basketHistory) ||
                      (pendingInstruments.length > 0 &&
                        primaryInstruments.length > 0) ? (
                        <Text
                          fontSize="2xl"
                          mt="10"
                          mb="6"
                          fontWeight="bold"
                          color="#244c9c"
                        >
                          Primary Instrument List
                        </Text>
                      ) : (
                        ""
                      )}

                      <Table size="md" width="100%">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                            <Th width="60%">
                              {" "}
                              <strong>Reason</strong>{" "}
                            </Th>{" "}
                            {/* Increased width */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentPrimaryData.map((instrument, index) => (
                            <Tr key={index}>
                              <Td>{startPrimaryIndex + index + 1}</Td>
                              <Td>{instrument.instrument}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.cmp}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.400"
                                    : "red.400"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                              <Td
                                wordBreak="break-word"
                                whiteSpace="nowrap"
                                maxWidth="90%"
                                padding="4"
                                // border="1px solid #ccc"
                                backgroundColor="gray.50"
                              >
                                {instrument.reason === "" ? (
                                  <Text color="gray.500" fontStyle="italic">
                                    Pending
                                  </Text>
                                ) : (
                                  <Text fontSize="sm" color="blackAlpha.800">
                                    {instrument.reason}
                                  </Text>
                                )}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>

                      {/* Pagination Controls */}
                      {totalPrimaryInstrumentPages > 1 && (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="6"
                        >
                          <Button
                            onClick={() =>
                              handlePrimaryInstrumentPageChange(
                                currentPrimaryPage - 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaArrowLeft />}
                            isDisabled={currentPrimaryPage === 1}
                            bg={
                              currentPrimaryPage === 1 ? "gray.300" : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentPrimaryPage === 1
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Previous
                          </Button>

                          <Text fontWeight="bold" color="#244c9c">
                            Page {currentPrimaryPage} of{" "}
                            {totalPrimaryInstrumentPages}
                          </Text>

                          <Button
                            onClick={() =>
                              handlePrimaryInstrumentPageChange(
                                currentPrimaryPage + 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            rightIcon={<FaArrowRight />}
                            isDisabled={
                              currentPrimaryPage === totalPrimaryInstrumentPages
                            }
                            bg={
                              currentPrimaryPage === totalPrimaryInstrumentPages
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentPrimaryPage ===
                                totalPrimaryInstrumentPages
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Pagination Controls */}
                  {/* {totalPages > 1 && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mt="6"
                    >
                      <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                        isDisabled={currentPage === 1}
                        bg={currentPage === 1 ? "gray.300" : "blue.500"}
                        _hover={{
                          bg: currentPage === 1 ? "gray.300" : "blue.400",
                        }}
                      >
                        Previous
                      </Button>

                      <Text fontWeight="bold" color="#244c9c">
                        Page {currentPage} of {totalPages}
                      </Text>

                      <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                        isDisabled={currentPage === totalPages}
                        bg={
                          currentPage === totalPages ? "gray.300" : "blue.500"
                        }
                        _hover={{
                          bg:
                            currentPage === totalPages
                              ? "gray.300"
                              : "blue.400",
                        }}
                      >
                        Next
                      </Button>
                    </Flex>
                  )} */}
                </>
              </TabPanel>
              {/* <=========================================Client Data Displayed========================> */}
              {/* <TabPanel>
                <Table variant="simple" colorScheme="teal" size="sm">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Name</Th>
                      
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentInstrumentsClient.map((client, index) => (
                      <Tr key={index}>
                        <Td>{clientStartIndex + index + 1}</Td>
                        <Td color="blue.500" fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>{client.name}</Td>
                       
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Flex justifyContent="space-between" mt="4">
                  {clientCurrentPage === 1 ? (
                    <Button size="sm" colorScheme="gray" leftIcon={<FaArrowLeft />}>
                      Previous
                    </Button>
                  ) : (
                    <Button onClick={() => handlePageChangeClient(clientCurrentPage - 1)} size="sm" colorScheme="blue" leftIcon={<FaArrowLeft />}>
                      Previous
                    </Button>
                  )}
                  <Text>Page {clientCurrentPage} of {totalClientPages}</Text>
                  {clientCurrentPage === totalClientPages ? (
                    <Button size="sm" colorScheme="gray" rightIcon={<FaArrowRight />}>
                      Next
                    </Button>
                  ) : (
                    <Button onClick={() => handlePageChangeClient(clientCurrentPage + 1)} size="sm" colorScheme="blue" rightIcon={<FaArrowRight />}>
                      Next
                    </Button>
                  )}
                </Flex>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Box>

        {/* Drawer for editing instruments */}
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
          <DrawerOverlay />
          <DrawerContent bg="white" borderRadius="lg" boxShadow="xl">
            <DrawerCloseButton />
            <DrawerHeader
              fontSize="lg"
              fontWeight="semibold"
              textAlign="center"
              borderBottom="1px solid"
              borderColor="gray.200"
              py="3"
              bg="gray.50"
              color="black"
              textTransform="uppercase"
              letterSpacing="wider"
            >
              Edit Instruments
            </DrawerHeader>

            <DrawerBody px="4" py="4">
              <Box mb="4">
                <Box display={"flex"}>
                  <Box display={"flex"}>
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      ml={2}
                      mb="2"
                      color="gray.600"
                    >
                      Basket Value
                    </Text>
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      ml={2}
                      mb="2"
                      color="gray.600"
                    >
                      {totalFundRequired}
                    </Text>
                  </Box>

                  <Box display={"flex"}>
                    <Text
                      fontSize="md"
                      fontWeight="lg"
                      ml={2}
                      mb="2"
                      color="black.600"
                    >
                      New Basket Value
                    </Text>
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      ml={2}
                      mb="2"
                      color="gray.600"
                    >
                      {newBasketValue}
                    </Text>
                  </Box>
                </Box>

                <Flex>
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    mb="2"
                    color="gray.600"
                  >
                    Instrument Details
                  </Text>

                  <Spacer />
                  <Button
                    colorScheme="green"
                    onClick={onAddOpen}
                    mb="4"
                    size="sm"
                  >
                    Add Script
                  </Button>
                </Flex>

                <Table variant="simple" colorScheme="blue" size="md">
                  <Thead bg="gray.100">
                    <Tr>
                      <Th textAlign="center">#</Th>
                      <Th>Script Name</Th>
                      <Th textAlign="center">CMP</Th>
                      <Th textAlign="center">QTY</Th>
                      <Th textAlign="center">Fund Req</Th>
                      <Th textAlign="center">Stop Loss</Th>
                      <Th textAlign="center">Take Profit</Th>
                      <Th textAlign="center">Exit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {editableInstruments.map((instrument, index) => (
                      <Tr key={index} _hover={{ bg: "gray.50" }}>
                        <Td textAlign="center">{index + 1}</Td>
                        <Td>{handleSymbolName(instrument.instrument)}</Td>
                        <Td textAlign="center">
                          {instrument?.creationPrice}
                        </Td>
                        <Td textAlign="center">{instrument.quantity}</Td>
                        <Td textAlign="center">
                          {calculateNewBasketFundREquired(instrument)}
                        </Td>
                        <Td textAlign="center">
                          <Input
                            value={instrument.stopLoss}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "stopLoss",
                                e.target.value
                              )
                            }
                            size="md" // Changed to medium size
                            borderColor="gray.300"
                            borderRadius="md"
                            width="100%" // Set width to full
                            _focus={{ borderColor: "red.400" }}
                            py="2" // Reduced padding for a smaller height
                            textAlign="center" // Center align text
                          />
                        </Td>
                        <Td textAlign="center">
                          <Input
                            value={instrument.takeProfit}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "takeProfit",
                                e.target.value
                              )
                            }
                            size="md" // Changed to medium size
                            borderColor="gray.300"
                            borderRadius="md"
                            width="100%" // Set width to full
                            _focus={{ borderColor: "green.400" }}
                            py="2" // Reduced padding for a smaller height
                            textAlign="center" // Center align text
                          />
                        </Td>
                        {/* <Td textAlign="center">
                          <Button
                            leftIcon={<DeleteIcon />}
                            colorScheme="red"
                            size="sm"
                            _hover={{ bg: "red.100", transform: "scale(1.05)" }}
                            onClick={() => handleDelete(index)}
                          >
                            Exit
                          </Button>
                        </Td> */}
                        <Td textAlign="center">
                          {instrument.orderType !== "EXIT" ? (
                            <Button
                              leftIcon={<DeleteIcon />}
                              colorScheme="red"
                              size="sm"
                              _hover={{
                                bg: "red.100",
                                transform: "scale(1.05)",
                              }}
                              onClick={() => handleDelete(index)}
                            >
                              Exit
                            </Button>
                          ) : (
                            <Text color="red.500">Exited</Text>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </DrawerBody>

            <DrawerFooter
              borderTop="1px solid"
              borderColor="gray.200"
              bg="gray.50"
              py="3"
            >
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSaveChanges}
                size="md"
                fontWeight="medium"
                // borderRadius="full"
                px="6"
                _hover={{
                  bg: "blue.600",
                  color: "white",
                  transform: "scale(1.05)",
                }}
                 isLoading={isSaving}
    loadingText='Saving...'
              >
                Save
              </Button>
              <Button
                variant="outline"
                colorScheme="gray"
                size="md"
                // borderRadius="full"
                onClick={onClose}
                _hover={{
                  bg: "gray.200",
                  boxShadow: "lg",
                  transform: "scale(1.05)",
                }}
              >
                Cancel
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        {/* Add Instrument Modal */}

        <Drawer
          isOpen={isAddOpen}
          placement="right"
          onClose={onAddClose}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent bg="white" borderRadius="lg" boxShadow="xl">
            <DrawerCloseButton />
            <DrawerHeader
              fontSize="xl"
              fontWeight="semibold"
              textAlign="center"
              borderBottom="1px solid"
              borderColor="gray.200"
              py="4"
              bg="gray.50"
              color="black"
            >
              Add Script
            </DrawerHeader>

            <DrawerBody px="5" py="6">
              <FormControl mb="4">
                <FormLabel>Name</FormLabel>
                {/* <Box position="relative" ref={dropdownRef}> */}
                {/* <InputGroup>
                    <Input
                      value={newInstrument.name}
                      onClick={handleDropdownToggle}
                      placeholder="Select Script"
                      variant="filled"
                      bg="gray.100"
                      _focus={{ bg: "white", borderColor: "gray.300" }}
                      readOnly
                      cursor="pointer"
                    />
                    <InputRightElement width="2.5rem">
                      <IconButton
                        aria-label="Dropdown icon"
                        icon={<MdArrowDropDown />}
                        variant="ghost"
                        onClick={handleDropdownToggle}
                      />
                    </InputRightElement>
                  </InputGroup>

                  {showOptions && (
                    <Box
                      position="absolute"
                      top="100%"
                      left={0}
                      right={0}
                      bg="white"
                      boxShadow="lg"
                      zIndex={10}
                      maxHeight="200px"
                      overflowY="auto"
                      borderRadius="md"
                      mt={1}
                      p={2}
                    >
                      <InputGroup mb={2}>
                        <Input
                          value={searchTerm}
                          onChange={(e) =>
                            handleSearchInputChange(e.target.value)
                          }
                          placeholder="Search Scripts"
                          bg="gray.50"
                          border="1px"
                          borderColor="gray.300"
                          _focus={{ bg: "white", borderColor: "blue.500" }}
                        />
                        <InputRightElement width="2.5rem">
                          <IconButton
                            aria-label="Search database"
                            icon={<SearchIcon color="gray.500" />}
                            variant="ghost"
                            onClick={() => handleSearchInputChange("")}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <List spacing={1}>
                        {filteredSymbols.length ? (
                          filteredSymbols.map((item) => (
                            <ListItem
                              key={item.symbol}
                              onClick={() => {
                                setShowOptions(false); // Close dropdown on select
                                setNewInstrument({
                                  ...newInstrument,
                                  name: item.name,
                                  instrument: item.instrument, // Assuming `symbol` is the identifier for the instrument
                                  securityID: item.securityID, // Ensure that securityID is part of your item
                                  currentPrice:
                                    item.cmp === 0
                                      ? Math.floor(
                                          Math.random() * (500 - 10 + 1)
                                        ) + 10
                                      : item.cmp, // Ensure that currentPrice is part of your item
                                }); // Update the selected name and additional fields
                              }}
                              cursor="pointer"
                              _hover={{ background: "gray.100" }}
                              px={4}
                              py={2}
                              borderRadius="md"
                              bg="gray.50"
                              mb={1}
                            >
                              {item.name}
                            </ListItem>
                          ))
                        ) : (
                          <Text px={4} py={2} color="gray.500">
                            No options found
                          </Text>
                        )}
                      </List>
                    </Box>
                  )} */}

                <ScriptDropdown
                  symbols={filteredSymbols}
                  onSelect={handleSelectInstrument}
                />
                {/* </Box> */}
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Current Price</FormLabel>
                <Text bg="gray.100" p={2} borderRadius="md" fontWeight="bold">
                  {newInstrument.creationPrice}{" "}
                  {/* Show 'N/A' if no price is selected */}
                </Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Quantity</FormLabel>
                <Input
                  value={newInstrument.quantity}
                  onChange={(e) =>
                    setNewInstrument({
                      ...newInstrument,
                      quantity: Number(e.target.value),
                    })
                  }
                  placeholder="Quantity"
                  isRequired
                  type="number"
                />
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Stop Loss</FormLabel>
                <Input
                  value={newInstrument.stopLoss}
                  onChange={(e) =>
                    setNewInstrument({
                      ...newInstrument,
                      stopLoss: Number(e.target.value),
                    })
                  }
                  isRequired
                  placeholder="Stop Loss"
                  type="number"
                />
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Take Profit</FormLabel>
                <Input
                  value={newInstrument.takeProfit}
                  onChange={(e) =>
                    setNewInstrument({
                      ...newInstrument,
                      takeProfit: Number(e.target.value),
                    })
                  }
                  isRequired
                  placeholder="Take Profit"
                  type="number"
                />
              </FormControl>
            </DrawerBody>

            <DrawerFooter
              borderTop="1px solid"
              borderColor="gray.200"
              bg="gray.50"
              py="4"
            >
              <Button
                colorScheme="blue"
                onClick={handleAddInstrument}
                mr={3}
                size="md"
                fontWeight="medium"
                borderRadius="full"
                isDisabled={!isFormValid()} // Disable button if form is not valid
              >
                Add
              </Button>
              <Button variant="outline" colorScheme="gray" onClick={onAddClose}>
                Cancel
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mt={{ base: "5", md: "0" }}
        >
          <Text fontSize="xl" fontWeight="bold" mb="4" color="blue.500">
            Basket Info: {data.title}
          </Text>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <strong>Basket Name:</strong>
                </Td>
                <Td>
                  <Text fontSize="sm">{data.title}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Description:</strong>
                </Td>
                <Td>
                  <Text fontSize="sm">{data.description}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Rational:</strong>
                </Td>
                <Td>
                  {rationalLines.map((line, index) => (
                    <Box key={index} display="flex" mb={4}>
                      {/* <Text as="span" mr={2} fontSize="md" color="gray.700"> */}
                      <Heading as="h5" size="sm" mr={2}>
                        •
                      </Heading>
                      {/* </Text> */}
                      <Text fontSize="sm" color="gray.700">
                        {line}
                      </Text>
                    </Box>
                  ))}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Expiry Date:</strong>
                </Td>
                <Td>
                  <Text fontSize="sm">{data.expiryTime}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Creation Date:</strong>
                </Td>
                <Td>
                  <Text fontSize="sm">{data.creationTime}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Exchange Type:</strong>
                </Td>
                <Td>
                  <Text fontSize="sm">{data.exchangeType}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Minimum Fund Required:</strong>
                </Td>
                <Td>
                  <Text fontSize="sm">{totalFundRequired}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Research Head Approval:</strong>
                </Td>
                <Td>
                  {status === "PENDING" && (
                    <Text
                      bg="gray.200"
                      p="1"
                      px="3"
                      width={{ base: "80%", md: "50%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                      fontSize="sm"
                    >
                      Pending
                    </Text>
                  )}

                  {status === "APPROVED" && (
                    <Text
                      bg="green.200"
                      p="1"
                      px="3"
                      width={{ base: "80%", md: "50%", lg: "55%" }}
                      textAlign={"center"}
                      borderRadius="md"
                      fontSize="sm"
                    >
                      Approved
                    </Text>
                  )}
                  {status === "REJECTED" && (
                    <Text
                      bg="red.200"
                      p="1"
                      px="3"
                      width={{ base: "80%", md: "50%", lg: "55%" }}
                      textAlign={"center"}
                      borderRadius="md"
                      fontSize="sm"
                    >
                      Rejected
                    </Text>
                  )}
                </Td>
              </Tr>
              {rejected ? (
                <Tr>
                  <Td>
                    <strong>Rejected Reason:</strong>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      {rejectedReason !== ""
                        ? rejectedReason
                        : "Reason Is Not Mentioned"}
                    </Text>
                  </Td>
                </Tr>
              ) : (
                ""
              )}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Box>
  );
}
